import { gql } from "@apollo/client";
import { Spinner, useToast } from "@chakra-ui/react";
import { useAuthContext } from "../../auth";
import { PlatformSettingsForm } from "../../components/PlatformSettingsForm";
import {
  PlatformSettingsDocument,
  usePlatformSettingsQuery,
  useUpdatePlatformSettingsMutation,
} from "../../graphql/generated/apollo";
import { useShopContext } from "../../shop";

gql`
query PlatformSettings($shop_id: uuid!){
  shop_mercari_shops_settings_by_pk(shop_id: $shop_id) {
    default_condition
    default_shipping_duration
    default_shipping_from_state_id
    default_shipping_method
  }
  shop_yahoo_shopping_settings_by_pk(shop_id: $shop_id) {
    postage_sets
    lead_times
  }
}
mutation UpdatePlatformSettings($mercari_shop_input: shop_mercari_shops_settings_insert_input!, $yahoo_shopping_input: shop_yahoo_shopping_settings_insert_input!) {
  insert_shop_mercari_shops_settings_one(
    object: $mercari_shop_input,
    on_conflict: {
      constraint: shop_mercari_shops_settings_pkey,
      update_columns: [default_condition, default_shipping_duration, default_shipping_from_state_id, default_shipping_method]
    }
  ) {
    default_condition
    default_shipping_duration
    default_shipping_from_state_id
    default_shipping_method
  }
  insert_shop_yahoo_shopping_settings_one(
    object: $yahoo_shopping_input,
    on_conflict: {
      constraint: shop_yahoo_shopping_settings_pkey,
      update_columns: [postage_sets, lead_times]
    }
  ) {
    lead_times
    postage_sets
  }
}
`;

export function Platforms() {
  const { user, isLoading: authLoading } = useAuthContext();
  const { currentShopId, isLoading: shopLoading } = useShopContext();
  const toast = useToast();
  const { data, loading, error } = usePlatformSettingsQuery({
    variables: {
      shop_id: currentShopId,
    },
    skip: !user || !currentShopId,
  });
  const [updatePlatformSettings] = useUpdatePlatformSettingsMutation({
    refetchQueries: [PlatformSettingsDocument],
    onCompleted: () => {
      toast({
        title: "保存しました",
        status: "success",
      });
    },
    onError: (e) => {
      toast({
        title: "エラーが発生しました",
        description: e.message,
        status: "error",
      });
    },
  });

  if (authLoading || shopLoading || loading) {
    return <Spinner />;
  }
  if (error) {
    toast({
      title: "エラーが発生しました",
      description: error.message,
      status: "error",
    });
  }

  return (
    <PlatformSettingsForm
      initialValue={{
        mercari_shops: data?.shop_mercari_shops_settings_by_pk ?? undefined,
        yahoo_shopping: data?.shop_yahoo_shopping_settings_by_pk ?? undefined,
      }}
      onSubmit={(data) => {
        updatePlatformSettings({
          variables: {
            mercari_shop_input: {
              ...data.mercari_shops,
              shop_id: currentShopId,
            },
            yahoo_shopping_input: {
              ...data.yahoo_shopping,
              shop_id: currentShopId,
            },
          },
        });
      }}
    />
  );
}
