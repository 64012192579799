import { Button, FormControl, FormLabel, Input, VStack, useToast } from "@chakra-ui/react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { auth } from "../firebase";

export type PasswordResetFormProps = {
  email?: string;
  state?: "forgot" | "reset" | "setup";
};

export function PasswordResetForm(props: PasswordResetFormProps) {
  const [email, setEmail] = useState(props.email ?? "");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast({
          title:
            "パスワード再設定メールを送信しました。届かない場合は迷惑メールフォルダをご確認の上、1分後に再度お試しください。",
          status: "success",
          isClosable: true,
        });
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert("入力されたメールアドレスは登録されていません。");
        } else {
          alert(`エラーが発生しました。スクリーンショットを保存し、お問い合わせください。\n${error.message}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={3}>
        <FormControl id="email">
          <FormLabel>メールアドレス</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={handleEmailChange}
            variant={props.state !== "forgot" && props.email ? "filled" : "outline"}
            isDisabled={props.state !== "forgot"}
            isRequired
          />
        </FormControl>
        <Button type="submit" colorScheme="teal" isLoading={loading} isDisabled={email === ""}>
          {props.state !== "setup" ? "再" : "パスワード"}設定メールを送信する
        </Button>
      </VStack>
    </form>
  );
}
