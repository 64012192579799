import type { User } from "firebase/auth";
import { type FC, type ReactNode, createContext, useContext, useState } from "react";
import { auth } from "./firebase";

const AuthContext = createContext<{
  user: User | null;
  isLoading: boolean;
  error: any;
}>({
  user: null,
  isLoading: true,
  error: null,
});

const useAuthContext = () => useContext(AuthContext);

interface AuthProviderProps {
  children?: ReactNode;
}

export const USER_AUTH_KEY = "user-auth";

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  auth.onIdTokenChanged((user) => {
    setIsLoading(false);
    if (user) {
      setCurrentUser(user);
    } else {
      setCurrentUser(null);
    }

    // TODO: remove
    localStorage.removeItem(USER_AUTH_KEY);
  });

  return <AuthContext.Provider value={{ user: currentUser, isLoading, error }}>{children}</AuthContext.Provider>;
};

export { AuthProvider, useAuthContext };
