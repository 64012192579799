import { Heading, Text } from "@chakra-ui/react";
import type { ReactNode } from "react";

export function Header({ children }: { children?: ReactNode }) {
  return (
    <Heading mt="4" pb="2" textAlign="left" fontSize="lg" color="gray.700">
      {children}
    </Heading>
  );
}
