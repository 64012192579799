import { gql } from "@apollo/client";
import { AddIcon, DeleteIcon, ExternalLinkIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Link,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  type YahooStoreCategory,
  useGetYahooAuctionCategoryQuery,
  useGetYahooShoppingPlatformSettingQuery,
  useListYahooShoppingCategoriesQuery,
  useListYahooStoreCategoriesQuery,
  useSearchYahooAuctionCategoryLazyQuery,
  useSearchYahooShoppingCategoriesLazyQuery,
  useSearchYahooShoppingCategoriesQuery,
} from "../graphql/generated/apollo";
import { useShopContext } from "../shop";
import { OptionalTag } from "./ItemForm/OptionalTag";

gql`
  query SearchYahooShoppingCategories(
    $input: SearchYahooShoppingCategoriesInput!
  ) {
    searchYahooShoppingCategories(input: $input) {
      yahooShoppingCategories {
        code
        name
        namePath
      }
    }
  }
  query GetYahooAuctionCategory($id: Int!) {
    yahoo_auction_categories_by_pk(id: $id) {
      ...YahooAuctionCategorySelect_YahooAuctionCategory
    }
  }
  query SearchYahooAuctionCategory($cond: yahoo_auction_categories_bool_exp!) {
    yahoo_auction_categories(where: $cond) {
      ...YahooAuctionCategorySelect_YahooAuctionCategory
    }
  }
  query GetYahooShoppingPlatformSetting($shop_id: uuid!){
    shop_yahoo_shopping_settings_by_pk(shop_id: $shop_id) {
      postage_sets
      lead_times
    }
  }
  fragment YahooShoppingCategorySelect_YahooAuctionCategory on SearchedYahooShoppingCategory {
    code
    name
    namePath
  }
  fragment YahooAuctionCategorySelect_YahooAuctionCategory on yahoo_auction_categories {
    id
    name
    name_path
  }
`;

type YahooExhibitionSettings = {
  store_category_hierarchies: string[][];
  product_category?: number;
  shopping_category_hierarchy?: number[];
  brand_code?: number | null;
  condition?: number | null;
  postage_set?: number | null;
  lead_time_instock?: number | null;
  lead_time_outstock?: number | null;
  auction_shipping_from_prefecture?: string | null;
  auction_category?: number | null;
};

type YahooExhibitionSettingsFormProps = {
  yahooExhibitionSettings?: YahooExhibitionSettings;
  onChange: (yahooExhibitionSettings: YahooExhibitionSettings) => void;
};

export function YahooExhibitionSettingsForm(props: YahooExhibitionSettingsFormProps) {
  const { yahooExhibitionSettings, onChange } = props;
  if (!yahooExhibitionSettings) {
    return <Spinner />;
  }
  const { currentShopId } = useShopContext();
  if (!currentShopId) {
    return <Spinner />;
  }
  const [auctionEnabled, setAuctionEnabled] = useState<boolean>(false);
  const { data: platformSetting } = useGetYahooShoppingPlatformSettingQuery({ variables: { shop_id: currentShopId } });

  return (
    <VStack>
      <Box pl={4} w="100%" textAlign="left">
        <Checkbox
          isChecked={auctionEnabled}
          onChange={(e) => {
            const auctionEnabled = e.target.checked;
            setAuctionEnabled(auctionEnabled);
            onChange({
              ...yahooExhibitionSettings,
              condition: decideCondition(yahooExhibitionSettings.condition ?? undefined, auctionEnabled),
            });
          }}
        >
          Yahoo!オークション併売機能を利用する
        </Checkbox>
        <Box fontSize="sm">
          ご利用にはYahoo!でのお申し込みが必要です。
          <br />
          詳しくは
          <Link href="https://auctions.yahoo.co.jp/store/business/index.html" isExternal>
            Yahoo!オークションストア出店案内
            <ExternalLinkIcon mx="2px" />
          </Link>
          のページをご覧ください
        </Box>
      </Box>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">ショッピングカテゴリ</Text>
        </FormLabel>
        <YahooShoppingCategoryForm
          shopId={currentShopId}
          category={yahooExhibitionSettings.product_category}
          categoryHierarchy={yahooExhibitionSettings.shopping_category_hierarchy}
          onChange={(categoryHierarchy, category) => {
            onChange({
              ...yahooExhibitionSettings,
              product_category: category,
              shopping_category_hierarchy: categoryHierarchy,
            });
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">ストアカテゴリ（パス）</Text>
        </FormLabel>
        <YahooStoreCategorySelect
          categoryHierarchy={
            yahooExhibitionSettings.store_category_hierarchies.length > 0
              ? yahooExhibitionSettings.store_category_hierarchies[0]
              : []
          }
          shopId={currentShopId}
          onChange={(categories) => {
            const newState = [...yahooExhibitionSettings.store_category_hierarchies];
            newState[0] = categories;
            onChange({
              ...yahooExhibitionSettings,
              store_category_hierarchies: newState,
            });
          }}
        />
        {yahooExhibitionSettings.store_category_hierarchies.length > 1
          ? yahooExhibitionSettings.store_category_hierarchies.slice(1).map((row, i) => {
              return (
                <Fragment key={i}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text fontSize="xs">リンク{i + 1}</Text>
                    <IconButton
                      textAlign="right"
                      aria-label="Remove"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        const newState = [...yahooExhibitionSettings.store_category_hierarchies];
                        newState.splice(i + 1, 1);
                        onChange({
                          ...yahooExhibitionSettings,
                          store_category_hierarchies: newState,
                        });
                      }}
                    />
                  </Flex>
                  <YahooStoreCategorySelect
                    categoryHierarchy={row}
                    shopId={currentShopId}
                    onChange={(categories) => {
                      const newState = [...yahooExhibitionSettings.store_category_hierarchies];
                      newState[i + 1] = categories;
                      onChange({
                        ...yahooExhibitionSettings,
                        store_category_hierarchies: newState,
                      });
                    }}
                  />
                </Fragment>
              );
            })
          : null}
        <Button
          size="sm"
          mt={2}
          aria-label="Add"
          leftIcon={<AddIcon />}
          onClick={() => {
            onChange({
              ...yahooExhibitionSettings,
              store_category_hierarchies: [...yahooExhibitionSettings.store_category_hierarchies, []],
            });
          }}
        >
          パスへのリンクを追加する
        </Button>
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">状態</Text>
        </FormLabel>
        <ConditionSelect
          condition={yahooExhibitionSettings.condition}
          auctionEnabled={auctionEnabled}
          onChange={(condition) => {
            onChange({
              ...yahooExhibitionSettings,
              condition: condition,
            });
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">
            配送グループ
            <OptionalTag ml={2} />
          </Text>
        </FormLabel>
        {platformSetting?.shop_yahoo_shopping_settings_by_pk?.postage_sets?.length ? (
          <Select
            value={yahooExhibitionSettings.postage_set ?? ""}
            onChange={(e) => {
              onChange({
                ...yahooExhibitionSettings,
                postage_set: e.target.value ? Number(e.target.value) : undefined,
              });
            }}
          >
            <option value={""}>選択してください</option>
            {platformSetting?.shop_yahoo_shopping_settings_by_pk?.postage_sets?.map(
              (postageSet: { number: number; name: string }) => (
                <option value={postageSet.number} key={postageSet.number}>
                  {postageSet.name}
                </option>
              ),
            )}
          </Select>
        ) : (
          <>
            <NumberInput
              value={yahooExhibitionSettings.postage_set ?? undefined}
              onChange={(postageSet) => {
                onChange({
                  ...yahooExhibitionSettings,
                  postage_set: postageSet ? Number(postageSet) : undefined,
                });
              }}
            >
              <NumberInputField />
            </NumberInput>
          </>
        )}
      </FormControl>
      <FormControl>
        {platformSetting?.shop_yahoo_shopping_settings_by_pk?.lead_times?.length ? (
          <>
            <FormLabel>
              <Text fontSize="sm">
                発送日表示文言（在庫あり時）
                <OptionalTag ml={2} />
              </Text>
            </FormLabel>
            <Select
              value={yahooExhibitionSettings.lead_time_instock ?? ""}
              onChange={(e) => {
                onChange({
                  ...yahooExhibitionSettings,
                  lead_time_instock: e.target.value ? Number(e.target.value) : undefined,
                });
              }}
            >
              <option value={""}>選択してください</option>
              {platformSetting?.shop_yahoo_shopping_settings_by_pk?.lead_times?.map(
                (leadTime: { number: number; name: string }) => (
                  <option value={leadTime.number} key={leadTime.number}>
                    {leadTime.name}
                  </option>
                ),
              )}
            </Select>
          </>
        ) : (
          <>
            <FormLabel>
              <Text fontSize="sm">
                発送日表示文言番号（在庫あり時）
                <OptionalTag ml={2} />
              </Text>
            </FormLabel>
            <NumberInput
              value={yahooExhibitionSettings.lead_time_instock ?? undefined}
              onChange={(leadTime) => {
                onChange({
                  ...yahooExhibitionSettings,
                  lead_time_instock: leadTime ? Number(leadTime) : undefined,
                });
              }}
            >
              <NumberInputField />
            </NumberInput>
          </>
        )}
      </FormControl>
      <FormControl>
        {platformSetting?.shop_yahoo_shopping_settings_by_pk?.lead_times?.length ? (
          <>
            <FormLabel>
              <Text fontSize="sm">
                発送日表示文言（在庫なし時）
                <OptionalTag ml={2} />
              </Text>
            </FormLabel>
            <Select
              value={yahooExhibitionSettings.lead_time_outstock ?? ""}
              onChange={(e) => {
                onChange({
                  ...yahooExhibitionSettings,
                  lead_time_outstock: e.target.value ? Number(e.target.value) : undefined,
                });
              }}
            >
              <option value={""}>選択してください</option>
              {platformSetting?.shop_yahoo_shopping_settings_by_pk?.lead_times?.map(
                (leadTime: { number: number; name: string }) => (
                  <option value={leadTime.number} key={leadTime.number}>
                    {leadTime.name}
                  </option>
                ),
              )}
            </Select>
          </>
        ) : (
          <>
            <FormLabel>
              <Text fontSize="sm">
                発送日表示文言番号（在庫なし時）
                <OptionalTag ml={2} />
              </Text>
            </FormLabel>
            <NumberInput
              value={yahooExhibitionSettings.lead_time_outstock ?? undefined}
              onChange={(leadTime) => {
                onChange({
                  ...yahooExhibitionSettings,
                  lead_time_outstock: leadTime ? Number(leadTime) : undefined,
                });
              }}
            >
              <NumberInputField />
            </NumberInput>
          </>
        )}
      </FormControl>
      {platformSetting?.shop_yahoo_shopping_settings_by_pk ? null : (
        <Box fontSize="xs" textAlign="left">
          配送グループ、発送日表示文言は
          <Link to={"/settings/platforms"} as={ReactRouterLink} variant="outline">
            プラットフォーム固有の設定
          </Link>
          で保存可能です
        </Box>
      )}
      {auctionEnabled ? (
        <>
          <FormControl>
            <FormLabel>
              <Text fontSize="sm">Yahoo!オークション併売設定</Text>
            </FormLabel>
            <FormLabel>
              <Text fontSize="sm">発送元地域</Text>
            </FormLabel>
            <YahooAuctionShipFromPrefectureSelect
              prefectureCode={yahooExhibitionSettings.auction_shipping_from_prefecture ?? undefined}
              onChange={(prefectureCode) => {
                onChange({
                  ...yahooExhibitionSettings,
                  auction_shipping_from_prefecture: prefectureCode,
                });
              }}
            />
            <FormLabel>
              <Text fontSize="sm">
                Yahoo!オークション用カテゴリ
                <Tooltip label="スペースで区切ることで複数キーワードでの検索が可能です">
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            </FormLabel>
            <YahooAuctionCategorySelect
              initialCategory={yahooExhibitionSettings.auction_category ?? undefined}
              category={yahooExhibitionSettings.auction_category ?? undefined}
              onChange={(category) => {
                onChange({
                  ...yahooExhibitionSettings,
                  auction_category: category,
                });
              }}
            />
          </FormControl>
          <Box textAlign="left" fontSize="sm">
            Yahoo!オークションに正しく情報が掲載されない場合は
            <Link href="https://test-store-info.yahoo.co.jp/editor/j/452.html" isExternal>
              Yahoo!ショッピングのツールマニュアル
              <ExternalLinkIcon mx="2px" />
            </Link>
            をご覧ください
          </Box>
        </>
      ) : null}
    </VStack>
  );
}

type YahooShoppingCategoryFormProps = {
  shopId: string;
  category?: number;
  categoryHierarchy?: number[];
  onChange: (categoryHierarchy?: number[], category?: number) => void;
};

function YahooShoppingCategoryForm({ shopId, category, categoryHierarchy, onChange }: YahooShoppingCategoryFormProps) {
  const [mode, setMode] = useState<"hierarchy" | "search">(
    category && categoryHierarchy?.length === 0 ? "search" : "hierarchy",
  );

  const { data } = useSearchYahooShoppingCategoriesQuery({
    variables: {
      input: {
        shopId,
        query: category?.toString() ?? "",
      },
    },
    skip: !category,
  });

  return (
    <>
      <RadioGroup
        onChange={(value) => {
          if (value === "hierarchy" || value === "search") {
            setMode(value);
          }
        }}
        value={mode}
      >
        <Stack direction="row" sx={{ mb: 2 }}>
          <Radio value="hierarchy">階層で選ぶ</Radio>
          <Radio value="search">検索して選ぶ</Radio>
        </Stack>
      </RadioGroup>
      {mode === "search" ? (
        <SearchableYahooShoppingCategorySelect
          shopId={shopId}
          category={category}
          onChange={(category) => {
            // TODO: hierarchyはundefinedの方がいいけどスキーマ変える必要があるので一旦配列に戻す
            onChange([], category);
          }}
        />
      ) : (
        <YahooShoppingCategorySelect categoryHierarchy={categoryHierarchy ?? []} shopId={shopId} onChange={onChange} />
      )}
      <Box textAlign="left">
        現在のカテゴリ:
        {data?.searchYahooShoppingCategories?.yahooShoppingCategories?.length
          ? data.searchYahooShoppingCategories.yahooShoppingCategories[0]?.namePath
          : "未選択"}
      </Box>
    </>
  );
}

type YahooShoppingCategorySelectProps = {
  categoryHierarchy: number[];
  shopId: string;
  parentCategoryCode?: number;
  onChange: (categoryHierarchy: number[], category?: number) => void;
  depth?: number;
};

function YahooShoppingCategorySelect(props: YahooShoppingCategorySelectProps) {
  const { categoryHierarchy, shopId, parentCategoryCode, onChange } = props;
  if (!shopId) {
    return <Spinner />;
  }
  const depth = props.depth ?? 0;
  const [code, setCode] = useState<string | undefined>(
    categoryHierarchy.length > depth ? categoryHierarchy[depth].toString() : undefined,
  );

  const { data, loading, error } = useListYahooShoppingCategoriesQuery({
    variables: {
      input: {
        shopId: shopId,
        parentCode: parentCategoryCode,
      },
    },
    onCompleted: (data) => {
      // 末端のカテゴリーで1種類しかなければ自動で選択する
      const c = data.listYahooShoppingCategories?.yahooShoppingCategories ?? [];
      if (c.length === 1 && c[0]?.hasChild === false) {
        const detail = c[0];
        setCode(detail.code.toString());
        onChange([...categoryHierarchy.slice(0, depth), detail.code], detail.hasChild ? undefined : detail.code);
      }
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <p>カテゴリーデータの読み込みに失敗しました: {error.message}</p>;
  }

  return (
    <>
      <Select
        value={code}
        onChange={(e) => {
          if (!e.target.value) {
            setCode(undefined);
            onChange([...categoryHierarchy.slice(0, depth)], undefined);
            return;
          }
          setCode(e.target.value);
          const detail = data?.listYahooShoppingCategories?.yahooShoppingCategories?.find(
            (category) => category?.code === Number(e.target.value),
          );
          onChange(
            [...categoryHierarchy.slice(0, depth), Number(e.target.value)],
            detail && !detail.hasChild ? Number(e.target.value) : undefined,
          );
        }}
      >
        <option value={""}>選択してください</option>
        {data?.listYahooShoppingCategories?.yahooShoppingCategories?.map((category) =>
          category ? (
            <option value={category.code} key={category.code}>
              {category.name}
            </option>
          ) : null,
        )}
      </Select>
      {code &&
      data?.listYahooShoppingCategories?.yahooShoppingCategories?.find((category) => category?.code === Number(code))
        ?.hasChild ? (
        <YahooShoppingCategorySelect
          categoryHierarchy={categoryHierarchy}
          shopId={shopId}
          parentCategoryCode={Number(code)}
          onChange={onChange}
          depth={depth + 1}
        />
      ) : null}
    </>
  );
}

type SearchableYahooShoppingCategorySelectProps = {
  shopId: string;
  category?: number;
  onChange: (category?: number, namePath?: string) => void;
};

function SearchableYahooShoppingCategorySelect(props: SearchableYahooShoppingCategorySelectProps) {
  const { shopId, category, onChange } = props;
  const [query, setQuery] = useState("");

  const [search, { data, error, loading }] = useSearchYahooShoppingCategoriesLazyQuery();
  const categories = data?.searchYahooShoppingCategories.yahooShoppingCategories;

  return (
    <Stack>
      <Input
        onChange={(e) => {
          const query = e.target.value;
          setQuery(query);
          if (!query) {
            search({
              variables: {
                input: {
                  shopId,
                  query: category?.toString() ?? "",
                },
              },
            });
          }
          if (query.length >= 3) {
            search({
              variables: {
                input: {
                  shopId,
                  query,
                },
              },
            });
          }
        }}
        placeholder="カテゴリを検索 (3文字以上入力してください)"
      />
      <Select
        value={category?.toString() ?? ""}
        onChange={(e) => {
          if (!e.target.value) {
            onChange(undefined, undefined);
            return;
          }
          onChange(Number(e.target.value), categories?.find((c) => c?.code.toString() === e.target.value)?.namePath);
        }}
        disabled={loading || query.length < 3}
      >
        <option value={""}>
          {query.length >= 3
            ? loading
              ? "検索中"
              : `選択してください（${
                  categories?.length ? (categories.length === 100 ? "99+" : categories.length) : 0
                }件）`
            : "検索キーワードを入力してください"}
        </option>
        {category && !data ? (
          <option value={""} key={""}>
            {`選択済み: ${category}`}
          </option>
        ) : null}
        {data?.searchYahooShoppingCategories.yahooShoppingCategories?.map((c) => (
          <option value={c?.code} key={c?.namePath}>
            {c?.namePath}
          </option>
        ))}
      </Select>
    </Stack>
  );
}

type YahooStoreCategorySelectProps = {
  categoryHierarchy: string[];
  shopId: string;
  parentCategory?: string;
  onChange: (categoryHierarchy: string[]) => void;
  depth?: number;
};

function YahooStoreCategorySelect(props: YahooStoreCategorySelectProps) {
  const { categoryHierarchy, shopId, parentCategory, onChange } = props;
  if (!shopId) {
    return <Spinner />;
  }
  const depth = props.depth ?? 0;
  const [key, setKey] = useState<string | undefined>(
    categoryHierarchy.length > depth && categoryHierarchy[depth] ? categoryHierarchy[depth] : undefined,
  );

  const { data, loading, error } = useListYahooStoreCategoriesQuery({
    variables: {
      input: {
        shopId: shopId,
        pageKey: parentCategory,
      },
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <p>カテゴリーデータの読み込みに失敗しました: {error.message}</p>;
  }
  if (
    !data ||
    !data.listYahooStoreCategories ||
    !data.listYahooStoreCategories.yahooStoreCategories ||
    data.listYahooStoreCategories.yahooStoreCategories.length === 0
  ) {
    return null;
  }

  return (
    <>
      <Select
        value={key}
        onChange={(e) => {
          if (!e.target.value) {
            setKey(undefined);
            const hierarchy = [...categoryHierarchy.slice(0, depth)];
            onChange(hierarchy);
            return;
          }
          setKey(e.target.value);
          const hierarchy = [...categoryHierarchy.slice(0, depth), e.target.value];
          onChange(hierarchy);
        }}
      >
        <option value={""}>選択する</option>
        {data?.listYahooStoreCategories?.yahooStoreCategories?.map((category) =>
          category ? (
            <option value={category.pageKey} key={category.pageKey}>
              {category.name}
            </option>
          ) : null,
        )}
      </Select>
      {key ? (
        <YahooStoreCategorySelect
          categoryHierarchy={categoryHierarchy}
          shopId={shopId}
          parentCategory={key}
          onChange={onChange}
          depth={depth + 1}
        />
      ) : null}
    </>
  );
}

type YahooStoreCategoryCheckboxSelectProps = {
  categories: string[];
  shopId: string;
  onChange: (categories: string[]) => void;
};

type ConditionSelectProps = {
  condition?: number | null;
  auctionEnabled: boolean;
  onChange: (condition?: number | null) => void;
};

function ConditionSelect(props: ConditionSelectProps) {
  const { condition, auctionEnabled, onChange } = props;
  /* 0 : 新品
    1 : 中古 （※古物免許を登録していないと中古に設定することはできません）
    2 : 新品：Yahoo!オークション併売 ※Yahoo!オークション併売プラン限定
    3 : 中古：未使用
    4 : 中古：未使用に近い
    5 : 中古：目立った傷や汚れなし
    6 : 中古：やや傷や汚れあり
    7 : 中古：傷や汚れあり
    8 : 中古：全体的に状態が悪い */
  const conditions = [
    auctionEnabled ? { name: "新品：Yahoo!オークション併売", value: 2 } : { name: "新品", value: 0 },
    {
      name: "※以下は古物免許を登録していないと設定することができません",
      disabled: true,
    },
    { name: "中古：未使用", value: 3 },
    { name: "中古：未使用に近い", value: 4 },
    { name: "中古：目立った傷や汚れなし", value: 5 },
    { name: "中古：やや傷や汚れあり", value: 6 },
    { name: "中古：傷や汚れあり", value: 7 },
    { name: "中古：全体的に状態が悪い", value: 8 },
  ];

  return (
    <Select
      value={decideCondition(condition ?? undefined, auctionEnabled)}
      onChange={(e) => {
        if (!e.target.value) {
          onChange(undefined);
          return;
        }
        onChange(Number(e.target.value));
      }}
    >
      <option value={""}>選択してください</option>
      {conditions.map((c, i) => (
        <option value={c.value?.toString() ?? ""} key={i} disabled={c.disabled}>
          {c.name}
        </option>
      ))}
    </Select>
  );
}

function decideCondition(condition?: number, auctionEnabled?: boolean) {
  if (condition === undefined || condition === 0 || condition === 2) {
    return auctionEnabled ? 2 : 0;
  }
  return condition;
}

type YahooAuctionCategorySelectProps = {
  category?: number;
  initialCategory?: number;
  onChange: (category?: number) => void;
};

function YahooAuctionCategorySelect(props: YahooAuctionCategorySelectProps) {
  const { category, initialCategory, onChange } = props;
  const [query, setQuery] = useState("");

  const useGetYahooAuctionCategoryHook = useGetYahooAuctionCategoryQuery({
    variables: {
      id: initialCategory ?? 0,
    },
    skip: !initialCategory,
  });

  const [search, searchResult] = useSearchYahooAuctionCategoryLazyQuery();

  return (
    <Stack>
      <Input
        onChange={(e) => {
          const query = e.target.value;
          setQuery(query);
          if (query.length >= 3) {
            // スペースで区切られたキーワードをAND検索する
            search({
              variables: {
                cond: {
                  is_leaf: { _eq: true },
                  _and: query
                    .replaceAll(/\s/g, " ")
                    .split(" ")
                    .filter((s) => s.length > 0)
                    .map((s) => ({ name_path: { _iregex: s } })),
                },
              },
            });
          }
        }}
        placeholder="カテゴリを検索 (3文字以上入力してください)"
      />
      <Select
        value={category?.toString() ?? ""}
        onChange={(e) => {
          if (!e.target.value) {
            onChange(undefined);
            return;
          }
          onChange(Number(e.target.value));
        }}
        disabled={searchResult.loading || query.length < 3}
      >
        <option value={""}>
          {query.length >= 3
            ? searchResult.loading
              ? "検索中"
              : `選択してください（${searchResult.data?.yahoo_auction_categories.length ?? 0}件）`
            : "検索キーワードを入力してください"}
        </option>
        {useGetYahooAuctionCategoryHook.data?.yahoo_auction_categories_by_pk ? (
          <option value={useGetYahooAuctionCategoryHook.data.yahoo_auction_categories_by_pk.id}>
            {useGetYahooAuctionCategoryHook.data.yahoo_auction_categories_by_pk.name_path}
          </option>
        ) : null}
        {searchResult.data?.yahoo_auction_categories?.map((c) => (
          <option value={c.id} key={c.id}>
            {c.name_path}
          </option>
        ))}
      </Select>
    </Stack>
  );
}

type YahooAuctionShipFromPrefecture = {
  prefectureCode?: string;
  onChange: (value?: string) => void;
};

function YahooAuctionShipFromPrefectureSelect(props: YahooAuctionShipFromPrefecture) {
  const { prefectureCode, onChange } = props;
  // see: https://nlftp.mlit.go.jp/ksj/gml/codelist/PrefCd.html
  const prefectures = [
    { value: 1, name: "北海道" },
    { value: 2, name: "青森県" },
    { value: 3, name: "岩手県" },
    { value: 4, name: "宮城県" },
    { value: 5, name: "秋田県" },
    { value: 6, name: "山形県" },
    { value: 7, name: "福島県" },
    { value: 8, name: "茨城県" },
    { value: 9, name: "栃木県" },
    { value: 10, name: "群馬県" },
    { value: 11, name: "埼玉県" },
    { value: 12, name: "千葉県" },
    { value: 13, name: "東京都" },
    { value: 14, name: "神奈川県" },
    { value: 15, name: "新潟県" },
    { value: 16, name: "富山県" },
    { value: 17, name: "石川県" },
    { value: 18, name: "福井県" },
    { value: 19, name: "山梨県" },
    { value: 20, name: "長野県" },
    { value: 21, name: "岐阜県" },
    { value: 22, name: "静岡県" },
    { value: 23, name: "愛知県" },
    { value: 24, name: "三重県" },
    { value: 25, name: "滋賀県" },
    { value: 26, name: "京都府" },
    { value: 27, name: "大阪府" },
    { value: 28, name: "兵庫県" },
    { value: 29, name: "奈良県" },
    { value: 30, name: "和歌山県" },
    { value: 31, name: "鳥取県" },
    { value: 32, name: "島根県" },
    { value: 33, name: "岡山県" },
    { value: 34, name: "広島県" },
    { value: 35, name: "山口県" },
    { value: 36, name: "徳島県" },
    { value: 37, name: "香川県" },
    { value: 38, name: "愛媛県" },
    { value: 39, name: "高知県" },
    { value: 40, name: "福岡県" },
    { value: 41, name: "佐賀県" },
    { value: 42, name: "長崎県" },
    { value: 43, name: "熊本県" },
    { value: 44, name: "大分県" },
    { value: 45, name: "宮崎県" },
    { value: 46, name: "鹿児島県" },
    { value: 47, name: "沖縄県" },
  ];

  return (
    <Select
      value={prefectureCode}
      onChange={(e) => {
        if (!e.target.value) {
          onChange(undefined);
          return;
        }
        onChange(e.target.value);
      }}
    >
      <option value={""}>選択してください</option>
      {prefectures.map((p, i) => (
        <option value={p.value.toString().padStart(2, "0")} key={i}>
          {p.name}
        </option>
      ))}
    </Select>
  );
}

function YahooStoreCategoryCheckboxSelect(props: YahooStoreCategoryCheckboxSelectProps) {
  const { categories, shopId, onChange } = props;
  if (!shopId) {
    return <Spinner />;
  }
  const depth = 0;
  const [checkedCategories, setCheckedCategories] = useState<string[]>(categories);

  const { data, loading, error } = useListYahooStoreCategoriesQuery({
    variables: {
      input: {
        shopId: shopId,
      },
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <p>カテゴリーデータの読み込みに失敗しました: {error.message}</p>;
  }
  if (data?.listYahooStoreCategories?.yahooStoreCategories?.length === 0) {
    return null;
  }

  return (
    <CheckboxGroup
      onChange={(v) => {
        setCheckedCategories(v.map((v) => v.toString()));
        onChange(v.map((v) => v.toString()));
      }}
    >
      <Stack>
        {data?.listYahooStoreCategories?.yahooStoreCategories?.map((category) =>
          category ? (
            <Fragment key={category.pageKey}>
              <YahooStoreCategoryCheckboxSelectCheckbox category={category} depth={depth} />
              <YahooStoreCategoryCheckboxSelectChildren
                categories={checkedCategories}
                shopId={shopId}
                parentPageKey={category.pageKey}
                depth={depth + 1}
              />
            </Fragment>
          ) : null,
        )}
      </Stack>
    </CheckboxGroup>
  );
}

type YahooStoreCategoryCheckboxSelectChildrenProps = {
  categories: string[];
  shopId: string;
  parentPageKey: string;
  depth: number;
};

function YahooStoreCategoryCheckboxSelectChildren(props: YahooStoreCategoryCheckboxSelectChildrenProps) {
  const { categories, shopId, parentPageKey } = props;
  if (!shopId) {
    return <Spinner />;
  }
  const depth = props.depth ?? 0;

  const { data, loading, error } = useListYahooStoreCategoriesQuery({
    variables: {
      input: {
        shopId: shopId,
        pageKey: parentPageKey,
      },
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <p>カテゴリーデータの読み込みに失敗しました: {error.message}</p>;
  }
  if (data?.listYahooStoreCategories?.yahooStoreCategories?.length === 0) {
    return null;
  }

  return data?.listYahooStoreCategories?.yahooStoreCategories?.map((category) =>
    category ? (
      <Fragment key={category.pageKey}>
        <YahooStoreCategoryCheckboxSelectCheckbox category={category} depth={depth} />
        {categories.includes(category.pageKey) ? (
          <YahooStoreCategoryCheckboxSelectChildren
            categories={categories}
            shopId={shopId}
            parentPageKey={category.pageKey}
            depth={depth + 1}
          />
        ) : null}
      </Fragment>
    ) : null,
  );
}

function YahooStoreCategoryCheckboxSelectCheckbox(props: {
  category: YahooStoreCategory;
  depth: number;
}) {
  const { category, depth } = props;
  return (
    <Checkbox value={category.pageKey} pl={6 * depth}>
      {category.name}
    </Checkbox>
  );
}
