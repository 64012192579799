import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link as ChakraLink, Spinner, Text } from "@chakra-ui/react";
import type React from "react";
import { useGetMercariShopsShopIdQuery, useListIntegrationsByShopQuery } from "../graphql/generated/apollo"; // クエリをここでインポート
import { platformKeyToName } from "../utils/platform";

type PlatformOrderLinkProps = {
  platform: string;
  shopId?: string | null;
};

export const PlatformOrderLink: React.FC<PlatformOrderLinkProps> = ({ platform, shopId }) => {
  const {
    data: listIntegrationsData,
    error: listIntegrationsError,
    loading: listIntegrationsLoading,
  } = useListIntegrationsByShopQuery({
    variables: {
      shop_id: shopId,
    },
    skip: platform !== "YAHOO_SHOPPING" || !shopId,
  });

  const {
    data: getMercariShopsShopIdData,
    error: getMercariShopsShopIdError,
    loading: getMercariShopsShopIdLoading,
  } = useGetMercariShopsShopIdQuery({
    variables: { shop_id: shopId ?? "" },
    skip: platform !== "MERCARI_SHOPS" || !shopId,
  });

  if (listIntegrationsLoading || getMercariShopsShopIdLoading) return platformKeyToName(platform);
  if (listIntegrationsError || getMercariShopsShopIdError) return platformKeyToName(platform);

  let orderLink = "#";
  let isLink = false;

  switch (platform) {
    case "BASE":
      orderLink = "https://admin.thebase.com/shop_admin/orders";
      isLink = true;
      break;
    case "STORES":
      orderLink = "https://dashboard.stores.jp/orders";
      isLink = true;
      break;
    case "MERCARI_SHOPS": {
      const mercariShopsShopId = getMercariShopsShopIdData?.getMercariShopsShopId?.shopId;
      if (mercariShopsShopId) {
        orderLink = `https://mercari-shops.com/seller/shops/${mercariShopsShopId}/orders`;
        isLink = true;
      }
      break;
    }
    case "YAHOO_SHOPPING": {
      const sellerId = listIntegrationsData?.integrations?.find((i) => i.platform === "YAHOO_SHOPPING")
        ?.yahoo_shopping_settings?.[0].seller_id;
      if (sellerId) {
        orderLink = `https://pro.store.yahoo.co.jp/pro.${sellerId}/order/manage/new_order`;
        isLink = true;
      }
      break;
    }
  }

  if (isLink) {
    return (
      <ChakraLink href={orderLink} isExternal>
        {platformKeyToName(platform)} <ExternalLinkIcon mx="2px" pos="relative" bottom="2px" right="2px" />
      </ChakraLink>
    );
  }

  return platformKeyToName(platform);
};
