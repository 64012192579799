import { gql } from "@apollo/client";
import { CheckCircleIcon, InfoOutlineIcon, QuestionIcon, WarningIcon } from "@chakra-ui/icons";
import { Box, List, ListIcon, ListItem, Spinner, Text, Tooltip, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useAuthContext } from "../auth";
import { useListExhibitionsByItemIdQuery } from "../graphql/generated/apollo";
import { platformKeyToName } from "../utils/platform";

gql`
  query ListExhibitionsByItemId($item_id: uuid!) {
    exhibitions(where: { item_id: { _eq: $item_id } }) {
      ...ExhibitionStatusIndicator_Exhibition
    }
  }
  fragment ExhibitionStatusIndicator_Exhibition on exhibitions {
    platform
    status
    error_message
  }
`;

const POLLING_INTERVAL_BASE = 1500;

export type ExhibitionStatusIndicatorProps = {
  itemId: string;
  maxPollingCount?: number;
};

export function ExhibitionStatusIndicator({ itemId, maxPollingCount = 20 }: ExhibitionStatusIndicatorProps) {
  const { user } = useAuthContext();
  const [pollingCount, setPollingCount] = useState(0);
  const { data, error, loading, stopPolling } = useListExhibitionsByItemIdQuery({
    variables: {
      item_id: itemId,
    },
    notifyOnNetworkStatusChange: true,
    skip: !user || !itemId,
    pollInterval: POLLING_INTERVAL_BASE, // FIXME: 本当はexponential backoffを利用したいが、うまく動作しないので固定値にしている
    onCompleted: () => {
      setPollingCount(pollingCount + 1);
    },
    fetchPolicy: "no-cache",
  });

  const isInProgress = data && data.exhibitions.filter((exhibition) => exhibition.status === "IN_PROGRESS").length > 0;
  const isAllSucceeded = data && data.exhibitions.every((exhibition) => exhibition.status === "COMPLETED");
  const isMaxRetried = pollingCount >= maxPollingCount;

  if ((data && data.exhibitions && !isInProgress) || isMaxRetried) {
    stopPolling();
  }

  return (
    <VStack alignItems="start">
      <Text>
        {!data || data.exhibitions.length === 0
          ? "商品データの登録が完了しました。"
          : isInProgress
            ? "商品を出品中…"
            : isAllSucceeded
              ? "商品の出品が完了しました。"
              : "商品の出品が一部を除いて完了しました。"}
      </Text>
      {loading && !data ? (
        <Spinner />
      ) : error ? (
        <Text>エラーが発生しました: {error.message}</Text>
      ) : (
        <List spacing={3} textAlign="left">
          {data?.exhibitions.map((exhibition) => (
            <ListItem key={exhibition.platform} color={colorByStatus(exhibition.status)}>
              {exhibition.status === "COMPLETED" ? (
                <ListIcon as={CheckCircleIcon} />
              ) : exhibition.status === "FAILED" ? (
                <Tooltip label="出品に失敗しました。商品情報に記入漏れなどがないか確認してください">
                  <ListIcon as={WarningIcon} />
                </Tooltip>
              ) : exhibition.status === "INCONSISTENT" ? (
                <Tooltip label="出品先のデータとラクダスのデータに食い違いが生じています。ラクダス側で商品を更新していただくか、出品先の在庫情報を見直してください。">
                  <ListIcon as={WarningIcon} />
                </Tooltip>
              ) : isMaxRetried ? (
                <Tooltip label="データの反映が遅れているようです。時間をおいて再度ご確認ください。">
                  <ListIcon as={QuestionIcon} />
                </Tooltip>
              ) : (
                <Box as="span" mr={2} w={4} h={4}>
                  <Spinner size="sm" />
                </Box>
              )}
              {platformKeyToName(exhibition.platform)}
              {exhibition.status === "FAILED" ? `: ${exhibition.error_message || "原因不明のエラー"}` : null}
            </ListItem>
          ))}
        </List>
      )}
      <Text fontSize="sm">
        <InfoOutlineIcon mr={2} />
        出品中でもこの画面は閉じることができます
      </Text>
    </VStack>
  );
}

function colorByStatus(status: string) {
  switch (status) {
    case "COMPLETED":
      return "green.500";
    case "FAILED":
      return "red.500";
    case "INCONSISTENT":
      return "yellow.500";
    default:
      return undefined;
  }
}
