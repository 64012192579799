import { gql } from "@apollo/client";
import { Box, FormControl, FormLabel, Input, Link, Select, Tag, Text, VStack } from "@chakra-ui/react";
import { type FC, memo, useEffect, useMemo, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  type ExhibitionMercariShopsSettingFragment,
  type MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSettingFragment,
  useGetMercariShopsSettingQuery,
} from "../graphql/generated/apollo";
import { useShopContext } from "../shop";
import { OptionalTag } from "./ItemForm/OptionalTag";

type MercariShopsCategory = {
  id: string;
  name: string;
  parentId: string | null;
  hasChild: boolean;
};

type MercariShopsBrand = {
  id: string;
  name: string;
  nameEn: string;
};

type MercariShopsState = {
  id: string;
  name: string;
};

gql`
  query GetMercariShopsSetting($shop_id: uuid!){
    shop_mercari_shops_settings_by_pk(shop_id: $shop_id) {
      ...MercariShopsExhibitionSettingsForm_ShopMercariShopsSetting
    }
    exhibition_mercari_shops_settings(
      where: { exhibition: { shop_id: { _eq: $shop_id } } }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      ...MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSetting
    }
  }
  fragment MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSetting on exhibition_mercari_shops_settings {
    brand_id
    category_id
    condition
    shipping_duration
    shipping_from_state_id
    shipping_method
    shipping_payer
  }
  fragment MercariShopsExhibitionSettingsForm_ShopMercariShopsSetting on shop_mercari_shops_settings {
    default_condition
    default_shipping_duration
    default_shipping_from_state_id
    default_shipping_method
  }
`;

export type MercariShopsExhibitionSettingsFormProps = {
  initialState?: MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSettingFragment;
  mappedBrandId?: string;
  mappedCondition?: string;
  onStateChanged: (state: MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSettingFragment) => void;
};

export const MemoizedMercariShopsExhibitionSettingsForm = memo((props: MercariShopsExhibitionSettingsFormProps) => (
  <MercariShopsExhibitionSettingsForm {...props} />
));

export function MercariShopsExhibitionSettingsForm(props: MercariShopsExhibitionSettingsFormProps) {
  const { currentShopId } = useShopContext();
  const { data, loading } = useGetMercariShopsSettingQuery({
    variables: {
      shop_id: currentShopId,
    },
  });
  const shopSetting = data?.shop_mercari_shops_settings_by_pk;
  const latestSetting = data?.exhibition_mercari_shops_settings.length
    ? data.exhibition_mercari_shops_settings[0]
    : null;

  const [states, setStates] = useState<MercariShopsState[]>([]);
  useEffect(() => {
    import("../assets/mercari-shops-states.json").then((data) => {
      if (Array.isArray(data.default)) {
        setStates(data.default as MercariShopsState[]);
      }
    });
  }, []);

  const [mercariShopsSetting, setMercariShopsSetting] = useState<ExhibitionMercariShopsSettingFragment>({
    brand_id: props.initialState?.brand_id ?? undefined,
    category_id: props.initialState?.category_id ?? "",
    condition: shopSetting?.default_condition ?? props.initialState?.condition ?? "",
    shipping_duration:
      props.initialState?.shipping_duration ??
      shopSetting?.default_shipping_duration ??
      latestSetting?.shipping_duration ??
      "",
    shipping_from_state_id:
      props.initialState?.shipping_from_state_id ??
      shopSetting?.default_shipping_from_state_id ??
      latestSetting?.shipping_from_state_id ??
      "",
    shipping_method:
      props.initialState?.shipping_method ??
      shopSetting?.default_shipping_method ??
      latestSetting?.shipping_method ??
      "",
    shipping_payer: "SELLER",
  });

  const onChange = (newState: ExhibitionMercariShopsSettingFragment) => {
    setMercariShopsSetting(newState);
    props.onStateChanged(newState);
  };
  useEffect(() => {
    if (loading) return;
    const newState = {
      brand_id: props.initialState?.brand_id ?? props.mappedBrandId ?? undefined,
      category_id: props.initialState?.category_id ?? "",
      condition: props.initialState?.condition ?? props.mappedCondition ?? shopSetting?.default_condition ?? "",
      shipping_duration:
        props.initialState?.shipping_duration ??
        shopSetting?.default_shipping_duration ??
        latestSetting?.shipping_duration ??
        "",
      shipping_from_state_id:
        props.initialState?.shipping_from_state_id ??
        shopSetting?.default_shipping_from_state_id ??
        latestSetting?.shipping_from_state_id ??
        "",
      shipping_method:
        props.initialState?.shipping_method ??
        shopSetting?.default_shipping_method ??
        latestSetting?.shipping_method ??
        "",
      shipping_payer: "SELLER",
    };
    if (
      newState.brand_id === mercariShopsSetting.brand_id &&
      newState.category_id === mercariShopsSetting.category_id &&
      newState.condition === mercariShopsSetting.condition &&
      newState.shipping_duration === mercariShopsSetting.shipping_duration &&
      newState.shipping_from_state_id === mercariShopsSetting.shipping_from_state_id &&
      newState.shipping_method === mercariShopsSetting.shipping_method &&
      newState.shipping_payer === mercariShopsSetting.shipping_payer
    ) {
      return;
    }

    onChange(newState);
  }, [props.initialState, props.mappedBrandId, props.mappedCondition, latestSetting]);

  return (
    <VStack>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">カテゴリー</Text>
        </FormLabel>
        <SearchableCategorySelect
          categoryId={mercariShopsSetting.category_id}
          onChange={(categoryId) => onChange({ ...mercariShopsSetting, category_id: categoryId ?? "" })}
        />
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">
            ブランド
            <OptionalTag ml={2} />
          </Text>
        </FormLabel>
        <SearchableBrandSelect
          onSelected={(v) => onChange({ ...mercariShopsSetting, brand_id: v })}
          initialSelectedBrandId={props.initialState?.brand_id ?? undefined}
        />
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">状態</Text>
        </FormLabel>
        <Select
          value={mercariShopsSetting.condition}
          onChange={(e) => onChange({ ...mercariShopsSetting, condition: e.target.value })}
        >
          <option value={""}>選択してください</option>
          <option value="BRAND_NEW">新品、未使用</option>
          <option value="ALMOST_NEW">未使用に近い</option>
          <option value="CLEAN">目立った傷や汚れなし</option>
          <option value="LITTLE_DIRTY">やや傷や汚れあり</option>
          <option value="DIRTY">傷や汚れあり</option>
          <option value="BAD">全体的に状態が悪い</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">配送方法</Text>
        </FormLabel>
        <Select
          value={mercariShopsSetting.shipping_method}
          onChange={(e) =>
            onChange({
              ...mercariShopsSetting,
              shipping_method: e.target.value,
            })
          }
        >
          <option value={""}>選択してください</option>
          <option value="UNDECIDED">未定（出品者が手配）</option>
          <option value="COOL">クール便</option>
          <option value="MERCARI_SHIPPING_YAMATO">らくらくメルカリ便</option>
          <option value="MERCARI_SHIPPING_YAMATO_COOL_REFRIGERATED">クールメルカリ便（冷蔵）</option>
          <option value="MERCARI_SHIPPING_YAMATO_COOL_FROZEN">クールメルカリ便（冷凍）</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">発送元の地域</Text>
        </FormLabel>
        <Select
          value={mercariShopsSetting.shipping_from_state_id}
          onChange={(e) =>
            onChange({
              ...mercariShopsSetting,
              shipping_from_state_id: e.target.value,
            })
          }
        >
          <option value={""}>選択してください</option>
          {states.map((state) => (
            <option key={state.id} value={state.id}>
              {state.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">発送までの日数</Text>
        </FormLabel>
        <Select
          value={mercariShopsSetting.shipping_duration}
          onChange={(e) =>
            onChange({
              ...mercariShopsSetting,
              shipping_duration: e.target.value,
            })
          }
        >
          <option value={""}>選択してください</option>
          <option value="ONE_TO_TWO_DAYS">１〜２日で発送</option>
          <option value="TWO_TO_THREE_DAYS">２〜３日で発送</option>
          <option value="FOUR_TO_SEVEN_DAYS">４〜７日で発送</option>
          <option value="EIGHT_DAYS_OR_MORE_OR_UNDECIDED">８〜９０日で発送</option>
        </Select>
      </FormControl>
      {shopSetting ? null : (
        <Box fontSize="xs" textAlign="left">
          初期設定の状態、発送情報は
          <Link to={"/settings/platforms"} as={ReactRouterLink} variant="outline">
            プラットフォーム固有の設定
          </Link>
          で保存可能です
        </Box>
      )}
    </VStack>
  );
}

type SearchableCategorySelectProps = {
  categoryId?: string;
  onChange: (categoryId?: string) => void;
};

function SearchableCategorySelect(props: SearchableCategorySelectProps) {
  const { categoryId, onChange } = props;

  const [categories, setCategories] = useState<MercariShopsCategory[]>([]);
  useEffect(() => {
    import("../assets/mercari-shops-categories.json").then((data) => {
      if (Array.isArray(data.default)) {
        setCategories(data.default as MercariShopsCategory[]);
      }
    });
  }, []);

  const [slug, setSlug] = useState<string>("");
  const slugAvailable = slug && slug.length >= 2;

  const filteredCategories = useMemo(() => {
    if (!slugAvailable) {
      return categories;
    }

    return findCategoryAndRelations(slug, categories);
  }, [slug, slugAvailable, categories]);

  const CategoryOptions = memo(() => generateCategoryOptions(filteredCategories));

  return (
    <VStack>
      <Input onChange={(e) => setSlug(e.target.value)} placeholder="検索ワードを2文字以上入力してください（β）" />
      <Select value={categoryId} onChange={(e) => onChange(e.target.value)}>
        <option value={""}>選択してください</option>
        <CategoryOptions />
      </Select>
    </VStack>
  );
}

// 子孫を再帰的に検索する関数
const findDescendants = (
  parentId: string,
  categories: MercariShopsCategory[],
  descendants: MercariShopsCategory[] = [],
): MercariShopsCategory[] => {
  const children = categories.filter((category) => category.parentId === parentId);
  descendants.push(...children);
  for (const child of children) {
    findDescendants(child.id, categories, descendants);
  }
  return descendants;
};

// 先祖を再帰的に検索する関数
const findAncestors = (
  childId: string,
  categories: MercariShopsCategory[],
  ancestors: MercariShopsCategory[] = [],
): MercariShopsCategory[] => {
  const parent = categories.find((category) => category.id === childId)?.parentId;
  if (parent) {
    const ancestor = categories.find((category) => category.id === parent);
    if (ancestor) {
      ancestors.push(ancestor);
      findAncestors(ancestor.id, categories, ancestors);
    }
  }
  return ancestors;
};

// 指定されたnameに部分一致するカテゴリとその子孫、先祖をすべて含む配列を返す関数
const findCategoryAndRelations = (slug: string, categories: MercariShopsCategory[]): MercariShopsCategory[] => {
  // 部分一致でマッチする全てのカテゴリを検索
  const matchedCategories = categories.filter((category) => category.name.includes(slug));
  if (matchedCategories.length === 0) return [];

  let result: MercariShopsCategory[] = [];

  for (const matchedCategory of matchedCategories) {
    const descendants = findDescendants(matchedCategory.id, categories);
    const ancestors = findAncestors(matchedCategory.id, categories);

    // 重複を避けるために、既に結果に含まれていない要素のみを追加
    result = [...result, matchedCategory, ...descendants, ...ancestors].filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id),
    );
  }

  return result;
};

const generateCategoryOptions = (
  categories: MercariShopsCategory[],
  parentId: string | null = null,
  level = 0,
): JSX.Element[] => {
  return categories
    .filter((category) => category.parentId === parentId)
    .flatMap((category) => [
      <option key={category.id} value={category.id} disabled={category.hasChild}>
        {"　".repeat(level) + category.name}
      </option>,
      ...(category.hasChild ? generateCategoryOptions(categories, category.id, level + 1) : []),
    ]);
};

const SearchableBrandSelect: FC<{
  onSelected: (value: string) => void;
  initialSelectedBrandId: string | undefined;
}> = ({ onSelected, initialSelectedBrandId }) => {
  const [slug, setSlug] = useState<string>("");
  const [selectedBrand, setSelectedBrand] = useState<string | undefined>(undefined);
  const [filteredBrands, setFilteredBrands] = useState<MercariShopsBrand[]>([]);

  const slugAvailable = slug && slug.length >= 2;

  const [brands, setBrands] = useState<MercariShopsBrand[]>([]);
  useEffect(() => {
    import("../assets/mercari-shops-brands.json").then((data) => {
      if (Array.isArray(data.default)) {
        setBrands(data.default as MercariShopsBrand[]);
      }
    });
  }, []);

  useEffect(() => {
    if (!slugAvailable) {
      const found = selectedBrand && brands.find((brand) => selectedBrand === brand.id);
      setFilteredBrands(found ? [found] : []);
      return;
    }

    const normalizedSlug = slug.toLowerCase();
    const results = brands.filter(
      (brand) =>
        // TODO: もうちょっと正規化
        selectedBrand === brand.id ||
        brand.name.toLowerCase().includes(normalizedSlug) ||
        brand.nameEn.toLowerCase().includes(normalizedSlug),
    );
    setFilteredBrands(results);
  }, [slug, brands, selectedBrand]);

  useEffect(() => {
    setSelectedBrand(initialSelectedBrandId);
  }, [initialSelectedBrandId]);

  return (
    <>
      <Input
        onChange={(e) => {
          setSlug(e.target.value);
        }}
        placeholder="ブランドを検索"
      />
      <Select
        value={selectedBrand ?? ""}
        onChange={(e) => {
          setSelectedBrand(e.target.value);
          onSelected(e.target.value);
        }}
      >
        <option value={""} disabled={!slugAvailable}>
          {slugAvailable ? `選択してください（${filteredBrands.length}ブランド）` : "検索ワードを入力してください"}
        </option>
        {filteredBrands.map((brand) => {
          return (
            <option value={brand.id} key={brand.id}>
              {brand.name}（{brand.nameEn}）
            </option>
          );
        })}
      </Select>
    </>
  );
};

export function conditionMapper(condition: string): string {
  switch (condition) {
    case "MINT":
      return "ALMOST_NEW";
    case "EXCELLENT":
      return "CLEAN";
    case "GOOD":
      return "LITTLE_DIRTY";
    case "FAIR":
      return "DIRTY";
    case "POOR":
      return "BAD";
    default:
      return "BRAND_NEW";
  }
}
