import { gql } from "@apollo/client";
import { Checkbox, CheckboxGroup, Spinner, Stack, Text } from "@chakra-ui/react";
import {
  type PlatformCategoryTree_CategoryFragment,
  useListPlatformCategoriesQuery,
} from "../graphql/generated/apollo";
import { useShopContext } from "../shop";

gql`
  query ListPlatformCategories($input: PlatformCategoriesInput!) {
    platform_categories(input: $input) {
      categories {
        ...PlatformCategoryTree_Category
      }
    }
  }
  fragment PlatformCategoryTree_Category on Category {
    id
    name
    path
  }
`;

export type PlatformCategoryTreeProps = {
  platform: string;
  selectedCategories: string[];
  initialSelectedCategories?: string[];
  onStateChanged: (selectedCategories: string[]) => void;
};

export function PlatformCategoryTree(props: PlatformCategoryTreeProps) {
  const { platform, selectedCategories } = props;
  const { currentShopId } = useShopContext();
  if (!currentShopId) {
    return <Spinner />;
  }

  const { data, error, loading } = useListPlatformCategoriesQuery({
    variables: {
      input: {
        platform,
        shopId: currentShopId,
      },
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <Text>エラーが発生しました: {error.message}</Text>;
  }

  const categories = data?.platform_categories?.categories ?? [];

  if (categories.length === 0) {
    return <Text>設定されていません</Text>;
  }

  const rootNodes = categories.filter((category) => !category?.path.includes("."));

  return (
    <Stack pl={4}>
      <CheckboxGroup value={selectedCategories}>
        {rootNodes.map((node) => (
          <PlatformCategoryNode
            key={node.path}
            node={node}
            depth={0}
            categories={categories}
            selectedCategories={selectedCategories}
            onCategoryClick={(category, checked) => {
              const newState = checked
                ? // 自分自身に加えて親もチェックする
                  [
                    ...new Set([
                      ...selectedCategories,
                      category.id,
                      ...categories.filter((c) => category.path.startsWith(c.path)).map((c) => c.id),
                    ]),
                  ]
                : categories
                    .filter((c) => selectedCategories.includes(c.id))
                    .filter((selectedCategory) => !selectedCategory.path.startsWith(category.path))
                    .map((c) => c.id);
              props.onStateChanged(newState);
            }}
          />
        ))}
      </CheckboxGroup>
    </Stack>
  );
}

type PlatformCategoryNodeProps = {
  node: PlatformCategoryTree_CategoryFragment;
  depth: number;
  categories: PlatformCategoryTree_CategoryFragment[];
  selectedCategories: string[];
  onCategoryClick?: (category: PlatformCategoryTree_CategoryFragment, checked: boolean) => void;
};

function PlatformCategoryNode(props: PlatformCategoryNodeProps) {
  const children = props.categories.filter((child) => {
    const childPathSegments = child.path.split(".");
    const nodePathSegments = props.node.path.split(".");
    return (
      child.path.startsWith(props.node.path) &&
      child.path !== props.node.path &&
      childPathSegments.length === nodePathSegments.length + 1
    );
  });
  const offset = 4;

  return (
    <>
      <Checkbox
        key={props.node?.id}
        value={props.node?.id}
        ml={props.depth * offset}
        onChange={(e) => props.onCategoryClick?.(props.node, e.target.checked)}
      >
        {props.node?.name}
      </Checkbox>
      {children.map((child) => (
        <PlatformCategoryNode
          key={child.path}
          node={child}
          depth={props.depth + 1}
          categories={props.categories}
          selectedCategories={props.selectedCategories}
          onCategoryClick={props.onCategoryClick}
        />
      ))}
    </>
  );
}
