import { useApolloClient } from "@apollo/client";
import { Button, Spinner } from "@chakra-ui/react";
import { useAuthContext } from "../auth";
import HeaderMenu from "../components/HeaderMenu";
import { LoginForm } from "../components/LoginForm";
import { auth } from "../firebase";

export function Login() {
  const { user, isLoading } = useAuthContext();
  const client = useApolloClient();

  return (
    <div>
      <HeaderMenu />
      {isLoading ? (
        <Spinner />
      ) : user ? (
        <>
          <div>すでにログイン済みです</div>
          <Button
            onClick={() => {
              auth.signOut();
              client.resetStore();
            }}
          >
            ログアウト
          </Button>
        </>
      ) : (
        <LoginForm />
      )}
    </div>
  );
}
