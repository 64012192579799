import { WarningIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { GeneralModal } from "./GeneralModal";

interface DeleteConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

export function DeleteConfirmModal({ isOpen, onClose, onConfirm, loading }: DeleteConfirmModalProps) {
  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      title="削除確認"
      body={
        <Text>
          <WarningIcon color="red.500" /> この商品情報を削除してもよろしいですか？
        </Text>
      }
      mainAction={onConfirm}
      mainActionLabel="削除する"
      loading={loading}
      bodyColor="red.500"
    />
  );
}
