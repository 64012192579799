import { type FC, type ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "./auth";
import { type ShopFragment, useInitializeLazyQuery } from "./graphql/generated/apollo";

const ShopContext = createContext<{
  shops: ShopFragment[];
  currentShopId: string | null;
  isActiveShop: boolean;
  isLoading: boolean;
  error: unknown;
}>({
  shops: [],
  currentShopId: null,
  isActiveShop: true,
  isLoading: false,
  error: null,
});

const useShopContext = () => useContext(ShopContext);

interface ShopProviderProps {
  children?: ReactNode;
}

const ShopProvider: FC<ShopProviderProps> = ({ children }) => {
  const [shops, setShops] = useState<ShopFragment[]>([]);
  const [currentShopId, setCurrentShopId] = useState<string | null>(localStorage.getItem("current-shop"));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, isLoading: userIsLoading } = useAuthContext();
  const [init] = useInitializeLazyQuery();

  useEffect(() => {
    if (userIsLoading) {
      return;
    }
    if (user) {
      setIsLoading(true);
      init()
        .then((resp) => {
          setIsLoading(false);
          if (resp.data?.shop_staff && resp.data.shop_staff.length > 0) {
            setShops(resp.data.shop_staff.map((enrolled) => enrolled.shop));
            setCurrentShopId(resp.data.shop_staff[0].shop.id);
            localStorage.setItem("current-shop", resp.data.shop_staff[0].shop.id);
          }
        })
        .catch((e) => {
          console.error("failed initialize", e);
          setError(e);
        });
    } else {
      setShops([]);
      setCurrentShopId(null);
      localStorage.removeItem("current-shop");
    }
  }, [init, user, userIsLoading]);

  return (
    <ShopContext.Provider
      value={{
        shops,
        currentShopId,
        isActiveShop: shops.find((shop) => shop.id === currentShopId)?.status === "ACTIVE",
        isLoading,
        error,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export { ShopProvider, useShopContext };
