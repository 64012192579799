import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  CheckboxGroup,
  Flex,
  Heading,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { memo, useCallback } from "react";
import type {
  ExhibitionMercariShopsSettingFragment,
  ExhibitionShopifySettingFragment,
  ExhibitionYahooShoppingSettingFragment,
} from "../graphql/generated/apollo";
import { useShopContext } from "../shop";
import { isFeatureEnabled } from "../utils/featureFlag";
import {
  MemoizedMercariShopsExhibitionSettingsForm,
  MercariShopsExhibitionSettingsForm,
} from "./MercariShopsExhibitionSettingsForm";
import { PlatformCategoryTree } from "./PlatformCategoryTree";
import { ShopifyExhibitionSettingForm } from "./ShopifyExhibitionSettingForm";
import { YahooExhibitionSettingsForm } from "./YahooExhibitionSettingsForm";

type PlatformExhibitionSettingsFormProps = {
  platforms: { key: string; name: string }[];
  integrations: { platform: string; disabled_at?: Date }[];
  selectedPlatforms?: string[];
  setSelectedPlatforms: (newState: string[]) => void;
  baseCategories: number[];
  setBaseCategories: (newState: number[]) => void;
  storesCategories: string[];
  setStoresCategories: (newState: string[]) => void;
  mercariShopsSetting?: ExhibitionMercariShopsSettingFragment;
  setMercariShopsSetting: (newState: ExhibitionMercariShopsSettingFragment) => void;
  yahooShoppingSetting?: ExhibitionYahooShoppingSettingFragment;
  setYahooShoppingSetting: (newState: ExhibitionYahooShoppingSettingFragment) => void;
  shopifySetting?: ExhibitionShopifySettingFragment;
  setShopifySetting: (newState: ExhibitionShopifySettingFragment) => void;
};

export const PlatformExhibitionSettingsForm = memo(function PlatformExhibitionSettingsForm({
  platforms,
  integrations,
  selectedPlatforms,
  setSelectedPlatforms,
  baseCategories,
  setBaseCategories,
  storesCategories,
  setStoresCategories,
  mercariShopsSetting,
  setMercariShopsSetting,
  yahooShoppingSetting,
  setYahooShoppingSetting,
  shopifySetting,
  setShopifySetting,
}: PlatformExhibitionSettingsFormProps) {
  const { currentShopId } = useShopContext();
  const cachedSetMercariShopsSetting = useCallback(setMercariShopsSetting, []);

  return (
    <Accordion
      index={platforms?.map((p, i) => (selectedPlatforms?.includes(p.key) ? i : -1)).filter((i) => i !== -1) ?? []}
      allowMultiple
      alignItems="start"
    >
      <CheckboxGroup value={selectedPlatforms} onChange={(value) => setSelectedPlatforms(value as string[])}>
        {platforms?.map((platform) => {
          const integration = integrations.find((integ) => integ.platform === platform.key);
          const disabled = integration && !!integration.disabled_at && new Date(integration.disabled_at) <= new Date();
          const selected = selectedPlatforms?.includes(platform.key);
          const deactivated = !selected && (!integration || disabled);

          return (
            <AccordionItem key={platform.key} sx={{ border: "none" }}>
              <Flex>
                {deactivated ? (
                  // FIXME: AccordionButtonを経由するとなぜかisDisabledが効かない
                  <AccordionButton
                    key={platform.key}
                    value={platform.key}
                    sx={{ border: "none" }}
                    _hover={{ border: "none" }}
                    _focus={{ border: "none" }}
                  >
                    <Checkbox isDisabled>{platform.name}</Checkbox>
                  </AccordionButton>
                ) : (
                  <AccordionButton as={Checkbox} key={platform.key} value={platform.key}>
                    {integration && !disabled ? (
                      platform.name
                    ) : (
                      <Tooltip label={`${disabled ? "再連携を行うまで" : "連携が削除されているため"}出品できません`}>
                        <Text as="del">{platform.name}</Text>
                      </Tooltip>
                    )}
                  </AccordionButton>
                )}
              </Flex>
              {!integration ? null : platform.key === "BASE" || platform.key === "STORES" ? (
                <AccordionPanel as={Card} variant="filled" size="sm">
                  <CardHeader>
                    <Heading size="xs" textAlign="left">
                      カテゴリ
                    </Heading>
                  </CardHeader>
                  <CardBody>
                    <PlatformCategoryTree
                      platform={platform.key}
                      selectedCategories={
                        platform.key === "BASE"
                          ? baseCategories.map((c) => c.toString())
                          : platform.key === "STORES"
                            ? storesCategories
                            : [] /* Unexpected */
                      }
                      initialSelectedCategories={
                        platform.key === "BASE"
                          ? storesCategories.map((c) => c.toString())
                          : platform.key === "STORES"
                            ? storesCategories
                            : [] /* Unexpected */
                      }
                      onStateChanged={(categories) => {
                        switch (platform.key) {
                          case "BASE":
                            setBaseCategories(categories.map((c) => Number.parseInt(c)));
                            if (categories.length !== 0 && !selectedPlatforms?.some((pf) => pf === "BASE")) {
                              setSelectedPlatforms([...(selectedPlatforms ?? []), "BASE"]);
                            }
                            break;
                          case "STORES":
                            setStoresCategories(categories);
                            if (categories.length !== 0 && !selectedPlatforms?.some((pf) => pf === "STORES")) {
                              setSelectedPlatforms([...(selectedPlatforms ?? []), "STORES"]);
                            }
                            break;
                        }
                      }}
                    />
                  </CardBody>
                </AccordionPanel>
              ) : platform.key === "MERCARI_SHOPS" ? (
                <AccordionPanel as={Card} variant="filled" size="sm">
                  <MemoizedMercariShopsExhibitionSettingsForm
                    initialState={mercariShopsSetting ?? undefined}
                    onStateChanged={cachedSetMercariShopsSetting}
                  />
                </AccordionPanel>
              ) : platform.key === "YAHOO_SHOPPING" ? (
                <AccordionPanel as={Card} variant="filled" size="sm">
                  <YahooExhibitionSettingsForm
                    yahooExhibitionSettings={yahooShoppingSetting}
                    onChange={(v) => {
                      setYahooShoppingSetting(v as ExhibitionYahooShoppingSettingFragment);
                    }}
                  />
                </AccordionPanel>
              ) : platform.key === "SHOPIFY" ? (
                <AccordionPanel as={Card} variant="filled" size="sm">
                  <ShopifyExhibitionSettingForm shopifySetting={shopifySetting} setShopifySetting={setShopifySetting} />
                </AccordionPanel>
              ) : null}
            </AccordionItem>
          );
        })}
      </CheckboxGroup>
    </Accordion>
  );
});
