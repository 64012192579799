import { Box, Flex, Image, LinkOverlay, Text, VStack } from "@chakra-ui/react";
import type React from "react";
import noImage from "../assets/no-image.svg";
import type { GetSoldItemsQuery } from "../graphql/generated/apollo";
import { filename } from "../utils/file";
import { PlatformOrderLink } from "./PlatformOrderLink";

type SoldItemCardProps = {
  soldItem: GetSoldItemsQuery["item_sold_histories"][0];
};

export const SoldItemCard: React.FC<SoldItemCardProps> = ({ soldItem }) => {
  const GCS_BASE_URL = "https://storage.googleapis.com";
  let firstImageURL = noImage;
  if (soldItem.item.images && soldItem.item.images.length > 0) {
    const firstImagePath = `${soldItem.item.images[0].path}/${filename("medium", soldItem.item.images[0].mime_type)}`;
    firstImageURL = new URL(firstImagePath, GCS_BASE_URL).toString();
  }

  return (
    <Box borderRadius="lg" bg="white" color="gray.700" p="4" boxShadow="sm" _hover={{ boxShadow: "md" }} width="full">
      <VStack align="start" spacing="4">
        <Flex gap="4" direction="row" width="100%">
          <Box boxSize="80px">
            <Image src={firstImageURL} />
          </Box>
          <VStack align="start">
            <Text fontSize="sm" color="gray.500">
              ID: {soldItem.id}
            </Text>
            <Text>{soldItem.item.name}</Text>
            <Text>販売価格: {soldItem.item.price}円</Text>
            <Text>
              受注元: <PlatformOrderLink platform={soldItem.platform} shopId={soldItem.item.shop_id} />
            </Text>
            {/* <Text>{soldItem.platform}</Text> */} {/* 受注元リンクとかぶるのでコメントアウト */}
            <Text>受注日時: {new Date(soldItem.created_at).toLocaleString()}</Text>
          </VStack>
        </Flex>
      </VStack>
    </Box>
  );
};
