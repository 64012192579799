import { Outlet } from "react-router-dom";
import { useAuthContext } from "../auth";
import HeaderMenu from "../components/HeaderMenu";

export function Layout() {
  return (
    <div className="App">
      <HeaderMenu />
      <Outlet />
    </div>
  );
}
