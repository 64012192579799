import { gql } from "@apollo/client";
import { ArrowDownIcon, ArrowLeftIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { Box, Button, ButtonGroup, Flex, IconButton, Tooltip, VStack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useAuthContext } from "../auth";
import { Order_By, useItemsQuery } from "../graphql/generated/apollo";
import { useShopContext } from "../shop";
import { ListingCard } from "./ListingCard";
import { SearchBar } from "./SearchBar";

const LIMIT_PER_FETCH = 200;
const LIMIT_PER_PAGE = 20;

gql`
  query Items($shop_id: uuid!, $cond: [items_bool_exp!] = {}, $order_by: [items_order_by!] = {created_at: desc}, $limit: Int = 200) {
    items(
      where: {
        shop_id: { _eq: $shop_id }
        _and: $cond
      }
      order_by: $order_by
      limit: $limit
    ) {
      ...ListedItemsTab_Item
    }
  }
  fragment ListedItemsTab_Item on items {
    id
    name
    price
    exhibitions {
      id
      platform
      platform_item_id
      status
      error_message
    }
    variants(order_by: { position: asc }) {
      id
      name
      stock_quantity
      position
    }
    images(order_by: [{ position: asc }, { created_at: asc }]) {
      id
      mime_type
      path
      created_at
    }
    item_status {
      name
    }
    status
    created_at
    updated_at
  }
`;

export function ListedItemsTab() {
  return (
    <>
      <ItemList />
    </>
  );
}

function ItemList() {
  const { user } = useAuthContext();
  const { currentShopId } = useShopContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [ascending, setAscending] = useState(false);
  const [page, setPage] = useState(1);
  const toast = useToast();
  const offset = (page - 1) * LIMIT_PER_PAGE;

  // 初期表示用のデータを取得し、検索時には検索結果を取得する。かつページネーションも行う
  const { data, loading, error, fetchMore, refetch } = useItemsQuery({
    variables: {
      shop_id: currentShopId,
      limit: LIMIT_PER_FETCH,
    },
    notifyOnNetworkStatusChange: true,
    skip: !user || !currentShopId,
    onCompleted: (data) => {
      if (data.items.length < LIMIT_PER_FETCH) {
        setHasMore(false);
      }
    },
  });

  if (!currentShopId) return <p>ショップが選択されていません</p>;
  if (error) return <p>Error : {error.message}</p>;

  const searchParam = (term: string) => {
    return term
      ? [
          {
            _or: [
              {
                name: { _ilike: `%${term}%` },
              },
              {
                description: { _ilike: `%${term}%` },
              },
            ],
          },
        ]
      : [];
  };
  const orderByParam = (asc: boolean) => {
    return asc ? { created_at: Order_By.Asc } : { created_at: Order_By.Desc };
  };
  const cursor = data?.items && data.items.length > 0 ? data.items[data.items.length - 1].created_at : null;
  const cursorParam = cursor ? [{ created_at: { _lt: cursor } }] : [];
  const totalPage = data ? Math.ceil(data.items.length / LIMIT_PER_PAGE) : 0;
  const movePage = (to: number, scrollToTop?: boolean) => {
    // 新規読み込みのあとに即ページ切り替えをする可能性があるので最終ページ+1まで許容
    if (to < 1 || totalPage + 1 < to) {
      return;
    }

    setPage(to);
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <Flex gap={2} justifyContent="space-between">
        <Box flex={1}>
          <SearchBar
            onSearch={async (term: string) => {
              setSearchTerm(term);
              setHasMore(true);
              const { data, error } = await refetch({
                shop_id: currentShopId,
                cond: searchParam(term),
                order_by: orderByParam(ascending),
                limit: LIMIT_PER_FETCH,
              });
              if (error) {
                toast({
                  title: "エラーが発生しました",
                  description: error.message,
                  status: "error",
                  isClosable: true,
                });
                return;
              }
              setHasMore(data.items.length >= LIMIT_PER_FETCH);
              movePage(1);
            }}
            loading={loading}
          />
        </Box>
        <Tooltip label={`登録日の${ascending ? "古い順" : "新しい順"}`}>
          <IconButton
            aria-label={ascending ? "ArrowUpIcon" : "ArrowDownIcon"}
            icon={ascending ? <ArrowUpIcon /> : <ArrowDownIcon />}
            onClick={() => {
              const newOrder = !ascending;
              setAscending(newOrder);
              setHasMore(true);
              refetch({
                shop_id: currentShopId,
                cond: searchParam(searchTerm),
                order_by: orderByParam(newOrder),
                limit: LIMIT_PER_FETCH,
              });
              movePage(1);
            }}
            isLoading={loading}
          />
        </Tooltip>
      </Flex>
      <VStack spacing="4" align="stretch" mt={2}>
        {data?.items.slice(offset, offset + LIMIT_PER_PAGE).map((item) => (
          <ListingCard key={item.id} item={item} />
        ))}
        {error && <p>Error : {error}</p>}
        {data?.items.length ? (
          <VStack>
            <ButtonGroup>
              {page > 5 && (
                <IconButton aria-label="ArrowLeftIcon" icon={<ArrowLeftIcon />} onClick={() => movePage(1, true)} />
              )}
              {page > 1 && page > 5 && <Box>...</Box>}
              {Array.from({ length: totalPage })
                .map((_, i) => i + 1)
                .filter((to) => {
                  // 今見ているページの前後4ページ分のボタンを表示する
                  return page - 4 <= to && to <= page + 4;
                })
                .map((to) => (
                  <Button key={to} onClick={() => movePage(to, true)} isActive={page === to}>
                    {to}
                  </Button>
                ))}
              {page < totalPage - 5 && (
                <>
                  <Box>...</Box>
                  {!hasMore && <Button onClick={() => movePage(totalPage, true)}>{totalPage}</Button>}
                </>
              )}
              {hasMore && (
                <Button
                  isLoading={loading}
                  ml={12}
                  onClick={() => {
                    fetchMore({
                      variables: {
                        shop_id: currentShopId,
                        cond: [...searchParam(searchTerm), ...cursorParam],
                        order_by: orderByParam(ascending),
                        limit: LIMIT_PER_FETCH,
                      },
                      updateQuery: ({ items }, { fetchMoreResult }) => {
                        setHasMore(fetchMoreResult.items.length >= LIMIT_PER_FETCH);
                        movePage(fetchMoreResult?.items.length ? totalPage + 1 : totalPage, true);
                        if (!fetchMoreResult) {
                          return { items };
                        }
                        return {
                          items: [...items, ...fetchMoreResult.items],
                        };
                      },
                    });
                  }}
                >
                  {totalPage}
                  {hasMore ? "+" : ""}
                </Button>
              )}
            </ButtonGroup>
            <Box fontSize="sm">
              {offset + 1} - {Math.min(offset + LIMIT_PER_PAGE, data.items.length)}/{data.items.length}
              {hasMore ? "+" : ""}
            </Box>
          </VStack>
        ) : null}
      </VStack>
    </>
  );
}
