import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, StackDivider, Text, VStack } from "@chakra-ui/react";
import { Link, Outlet } from "react-router-dom";
import HeaderMenu from "../components/HeaderMenu";

type SettingsProps = {
  label: string;
  to: string;
};

function SettingLink({ label, to }: SettingsProps) {
  return (
    <Flex as={Link} to={to} p={2} textAlign="left" justify="space-between">
      <Text>{label}</Text>
      <ChevronRightIcon fontSize={24} />
    </Flex>
  );
}

export function Settings() {
  return (
    <>
      <Heading fontSize="lg" textAlign="left" p={2}>
        設定
      </Heading>
      <Heading fontSize="md" textAlign="left" p={2}>
        商品登録・出品設定
      </Heading>
      <VStack align="stretch" divider={<StackDivider borderColor="gray.200" />}>
        <SettingLink label="プラットフォーム固有の設定" to="/settings/platforms" />
      </VStack>
    </>
  );
}
