import Cookies from "js-cookie";

export function generateStateKey(platform: string) {
  return `platform-${platform}-state`;
}

export function generateNonceKey(platform: string) {
  return `platform-${platform}-nonce`;
}

export function generateState() {
  return Math.random().toString(32).substring(2);
}

export function setState(key: string, state: string) {
  Cookies.set(key, state, {
    secure: !import.meta.env.VITE_WEB_DOMAIN.match(/^localhost/),
    sameSite: "strict",
  });
}

export function generateAndSetState(platform: string) {
  const key = generateStateKey(platform);
  const state = generateState();

  setState(key, state);

  return {
    key,
    state,
  };
}
