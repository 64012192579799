import { Alert, AlertDescription, AlertIcon, Box, Button, Flex, Image, Spinner } from "@chakra-ui/react";
import type * as React from "react";
import { Link } from "react-router-dom";

import { AddIcon, HamburgerIcon, SettingsIcon } from "@chakra-ui/icons";
import rakudasuLogo from "../assets/rakudasu-logo.png";
import { useAuthContext } from "../auth";

const HeaderMenu: React.FC = () => {
  const { user, isLoading } = useAuthContext();

  return (
    <>
      {/* <Alert status="success">
        <AlertIcon />
        <AlertDescription textAlign="left">
          2024/5/14 14:40ごろから2024/5/16
          12:30ごろまでYahoo!とのAPI接続に障害が発生してましたが、現在は復旧しております。
          <br />
          出品・在庫連携が正常に動作しない場合、お手数ですが再度ご連携をお願いいたします。
          <br />
          多大なるご不便とご迷惑をお掛けし、大変申し訳ございません。
        </AlertDescription>
      </Alert> */}
      <Flex
        as="nav"
        justifyContent="space-between"
        alignItems="center"
        padding="1.5rem"
        mb={8}
        // bg="yellow.300"
        bg="#FAFF00"
        color="gray.700"
        height="8"
        fontSize="xs"
      >
        <Box>
          <Link to="/">
            <Box>
              <Image src={rakudasuLogo} alt="logo" height="18px" width="88px" />
            </Box>
          </Link>
        </Box>
        <Flex gap={2}>
          {isLoading ? (
            <Spinner />
          ) : user ? (
            <>
              <Box as={Link} to="/listings" _hover={{ color: "gray.700" }} fontWeight="bold">
                <HamburgerIcon mr={1} boxSize="4" pb={0.5} />
                商品一覧
              </Box>
              <Box as={Link} to="/sell" _hover={{ color: "gray.700" }} fontWeight="bold">
                <AddIcon mr={1} boxSize="3" pb={0.5} />
                商品登録
              </Box>
              <Box as={Link} to="/settings" _hover={{ color: "gray.700" }}>
                <SettingsIcon pb={0.5} />
              </Box>
            </>
          ) : null}
        </Flex>
      </Flex>
    </>
  );
};

export default HeaderMenu;
