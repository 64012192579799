export function pad2dArray<T>(arr: T[][], pad?: T): T[][] {
  // arrが[[]]の場合、[]を返す
  if (arr.length === 1 && arr[0].length === 0) {
    return [];
  }

  // 最長の行の長さを求める
  const maxLength = Math.max(...arr.map((subArr) => subArr.length));

  // padがundefinedの場合、nullを代入する（Tがnullを許容する必要がある）
  const fillValue = pad === undefined ? null : pad;

  const newArr = [...arr];
  // 各行を最長の長さに合わせてpadで埋める
  return newArr.map((subArr) => {
    const newSubArr = [...subArr].filter(Boolean);
    while (newSubArr.length < maxLength) {
      newSubArr.push(fillValue as T);
    }
    return newSubArr;
  });
}
