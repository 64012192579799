export function isFeatureEnabled(
  feature: string,
  actor: {
    shopId?: string;
    userId?: string;
  },
): boolean {
  if (
    actor.shopId === "8c3af4e3-66e6-4080-b54c-d4f7b6eeeb28" || // Devalue Vintage
    actor.shopId === "fc69909f-200c-408d-b647-41ba753d4caf" || // Mean Collective
    actor.userId === "3ff429ee-0589-44a2-971c-d2b34ea4f5f2" // dev@
  ) {
    const flags = localStorage.getItem("_ff")?.split(",") ?? [];

    return flags.includes(feature);
  }

  return false;
}
