import { ListItem, Text, UnorderedList } from "@chakra-ui/react";
import type { ValidationWarning } from "../utils/item";
import { platformKeyToName } from "../utils/platform";
import { GeneralModal } from "./GeneralModal";

interface ListingConfirmModalProps {
  validationWarnings?: ValidationWarning[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

export function ListingConfirmModal({
  validationWarnings,
  isOpen,
  onClose,
  onConfirm,
  loading,
}: ListingConfirmModalProps) {
  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      title="出品確認"
      body={generateWarningMessage(validationWarnings) ?? "この商品を出品してもよろしいですか？"}
      mainAction={onConfirm}
      mainActionLabel="出品する"
      loading={loading}
    />
  );
}

export function generateWarningMessage(validationWarnings?: ValidationWarning[]) {
  const warnings =
    validationWarnings?.filter((warn) => warn.affectedPlatform && warn.affectedPlatform.length > 0) ?? [];
  if (warnings.length === 0) {
    return;
  }

  return (
    <>
      <Text>下記の内容にエラーがあります。</Text>
      <UnorderedList>
        {...warnings.map((warn) => {
          return (
            <ListItem key={warn.field + warn.reason}>
              {warn.field}が{warn.reason}
              {warn.reason.endsWith("い") || warn.reason.endsWith("る") ? "" : "の"}
              ため、
              {warn.affectedPlatform?.map(platformKeyToName).join(",")}
              への{warn.affect ?? "出品ができません"}。
            </ListItem>
          );
        })}
      </UnorderedList>
      <Text>この商品を登録しますか？</Text>
    </>
  );
}
