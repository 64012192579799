import { gql } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  type ShopifyExhibitionSettingForm_ShopifySettingFragment,
  useListPorductTypeHistoriesLazyQuery,
  useListVendorHistoriesLazyQuery,
} from "../graphql/generated/apollo";
import { useShopContext } from "../shop";

gql`
  query ListPorductTypeHistories($shopId: uuid!) {
    exhibition_shopify_settings(distinct_on: product_type, where: {exhibition: {shop_id: {_eq: $shopId}}}) {
      product_type
    }
  }
  query ListVendorHistories($shopId: uuid!) {
    exhibition_shopify_settings(distinct_on: vendor, where: {exhibition: {shop_id: {_eq: $shopId}}}) {
      vendor
    }
  }
  fragment ShopifyExhibitionSettingForm_shopifySetting on exhibition_shopify_settings {
    category
    product_type
    vendor
    tags
  }
`;

type ShopifyExhibitionSettingFormProps = {
  shopifySetting?: ShopifyExhibitionSettingForm_ShopifySettingFragment;
  setShopifySetting: (newState: ShopifyExhibitionSettingForm_ShopifySettingFragment) => void;
};

export function ShopifyExhibitionSettingForm({
  shopifySetting = {
    category: "",
    product_type: "",
    vendor: "",
    tags: [],
  },
  setShopifySetting,
}: ShopifyExhibitionSettingFormProps) {
  const { currentShopId } = useShopContext();

  const [listProductTypeHistoriesQuery, { data: productTypeHistoriesData, loading: productTypeHistoriesLoading }] =
    useListPorductTypeHistoriesLazyQuery({
      variables: {
        shopId: currentShopId,
      },
    });
  const [productTypeInputFocused, setProductTypeInputFocused] = useState(false);

  const [listVendorHistoriesQuery, { data: vendorHistoriesData, loading: vendorHistoriesLoading }] =
    useListVendorHistoriesLazyQuery({
      variables: {
        shopId: currentShopId,
      },
    });
  const [vendorInputFocused, setVendorInputFocused] = useState(false);

  const [tagInputValue, setTagInputValue] = useState("");
  const appendTag = (newTag: string) => {
    const trimmed = newTag.trim();
    if (trimmed === "") {
      return;
    }

    setShopifySetting({ ...shopifySetting, tags: [...shopifySetting.tags, trimmed] });
  };
  const removeTag = (index: number) => {
    setShopifySetting({ ...shopifySetting, tags: shopifySetting.tags.filter((_: string, i: number) => i !== index) });
  };
  const trimmedTagInputValue = tagInputValue.trim();

  return (
    <VStack>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">商品タイプ</Text>
        </FormLabel>
        <Input
          value={shopifySetting.product_type}
          onFocus={() => {
            setProductTypeInputFocused(true);
            setVendorInputFocused(false);
            listProductTypeHistoriesQuery();
          }}
          onChange={(e) => {
            setShopifySetting({ ...shopifySetting, product_type: e.target.value });
          }}
        />
        {productTypeInputFocused &&
          (productTypeHistoriesData?.exhibition_shopify_settings?.filter((history) => {
            if (shopifySetting.product_type === "") {
              return true;
            }
            if (history.product_type === shopifySetting.product_type) {
              return false;
            }

            return history.product_type.includes(shopifySetting.product_type);
          })?.length ||
            productTypeHistoriesLoading) && (
            <Box
              bg="white"
              border="1px"
              borderColor="gray.200"
              borderRadius="md"
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              width="full"
              zIndex={1}
              p={1}
            >
              {productTypeHistoriesLoading ? (
                <Spinner />
              ) : (
                productTypeHistoriesData?.exhibition_shopify_settings
                  ?.filter((history) => {
                    if (shopifySetting.product_type === "") {
                      return true;
                    }
                    if (history.product_type === shopifySetting.product_type) {
                      return false;
                    }

                    return history.product_type.includes(shopifySetting.product_type);
                  })
                  .map((history) => (
                    <Button
                      key={history.product_type}
                      width="full"
                      variant="ghost"
                      onClick={() => {
                        setShopifySetting({ ...shopifySetting, product_type: history.product_type });
                        setProductTypeInputFocused(false);
                      }}
                    >
                      {history.product_type}
                    </Button>
                  ))
              )}
            </Box>
          )}
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">販売元</Text>
        </FormLabel>
        <Input
          value={shopifySetting.vendor}
          onFocus={() => {
            setProductTypeInputFocused(false);
            setVendorInputFocused(true);
            listVendorHistoriesQuery();
          }}
          onChange={(e) => {
            setShopifySetting({ ...shopifySetting, vendor: e.target.value });
          }}
        />
        {vendorInputFocused &&
          (vendorHistoriesData?.exhibition_shopify_settings?.filter((history) => {
            if (shopifySetting.vendor === "") {
              return true;
            }
            if (history.vendor === shopifySetting.vendor) {
              return false;
            }

            return history.vendor.includes(shopifySetting.vendor);
          })?.length ||
            vendorHistoriesLoading) && (
            <Box
              bg="white"
              border="1px"
              borderColor="gray.200"
              borderRadius="md"
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              width="full"
              zIndex={1}
              p={1}
            >
              {vendorHistoriesLoading ? (
                <Spinner />
              ) : (
                vendorHistoriesData?.exhibition_shopify_settings
                  ?.filter((history) => {
                    if (shopifySetting.vendor === "") {
                      return true;
                    }
                    if (history.vendor === shopifySetting.vendor) {
                      return false;
                    }

                    return history.vendor.includes(shopifySetting.vendor);
                  })
                  .map((history) => (
                    <Button
                      key={history.vendor}
                      width="full"
                      variant="ghost"
                      onClick={() => {
                        setShopifySetting({ ...shopifySetting, vendor: history.vendor });
                        setProductTypeInputFocused(false);
                      }}
                    >
                      {history.vendor}
                    </Button>
                  ))
              )}
            </Box>
          )}
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text fontSize="sm">タグ（カンマ区切り）</Text>
        </FormLabel>
        <Input
          value={tagInputValue}
          onChange={(e) => {
            setProductTypeInputFocused(false);
            setVendorInputFocused(false);
            setTagInputValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              appendTag(tagInputValue);
              setTagInputValue("");
            }
          }}
        />
        {trimmedTagInputValue !== "" && !(shopifySetting.tags as string[]).includes(trimmedTagInputValue) && (
          <Box
            bg="white"
            border="1px"
            borderColor="gray.200"
            borderRadius="md"
            position="absolute"
            left="50%"
            transform="translateX(-50%)"
            width="full"
            zIndex={1}
            p={1}
          >
            <Button
              width="full"
              onClick={() => {
                appendTag(tagInputValue);
                setTagInputValue("");
              }}
            >
              {tagInputValue.trim()}を追加
            </Button>
          </Box>
        )}
        <Wrap spacing={2} mt={4}>
          {shopifySetting.tags.map((tag: string, index: number) => (
            <WrapItem key={tag}>
              <Tag
                size="lg"
                borderRadius="full"
                variant="solid"
                colorScheme={tag === trimmedTagInputValue ? "orange" : "blue"}
              >
                <TagLabel>{tag}</TagLabel>
                <TagCloseButton onClick={() => removeTag(index)} />
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </FormControl>
    </VStack>
  );
}
