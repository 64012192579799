import { useQuery } from "@apollo/client";
import { Spinner, Text, VStack } from "@chakra-ui/react";
import { useAuthContext } from "../auth"; // ここでユーザー情報を取得するフックを使います
import {
  GetSoldItemsDocument,
  type GetSoldItemsQuery,
  type GetSoldItemsQueryVariables,
} from "../graphql/generated/apollo";
import { SoldItemCard } from "./SoldItemCard";

type SoldItemsTabProps = {
  shopId: string;
};

const SoldItemsTab: React.FC<SoldItemsTabProps> = ({ shopId }) => {
  const { user } = useAuthContext(); // ユーザー情報を取得

  const { data, loading, error } = useQuery<GetSoldItemsQuery, GetSoldItemsQueryVariables>(GetSoldItemsDocument, {
    variables: { shop_id: shopId },
    skip: !user || !shopId, // userやshopIdがない場合はクエリをスキップ
  });

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    console.error("Error loading sold items:", error);
    console.error("GraphQL Errors:", error.graphQLErrors);
    //for...of文を使ってエラーを出力
    for (const err of error.graphQLErrors) {
      console.error("GraphQL Error:", err.message);
      console.error("Error Path:", err.path);
      console.error("Error Extensions:", err.extensions);
    }
    console.error("Network Error:", error.networkError);
    return <Text>Error: {error.message}</Text>;
  }

  return (
    <VStack>
      {data?.item_sold_histories.map((soldItem) => (
        <SoldItemCard key={soldItem.id} soldItem={soldItem as GetSoldItemsQuery["item_sold_histories"][0]} />
      ))}
    </VStack>
  );
};

export { SoldItemsTab };
