import { useApolloClient } from "@apollo/client";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Link,
  List,
  ListIcon,
  ListItem,
  Spinner,
  Tag,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { Link as ReactRouterLink, redirect, useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth";
import { GeneralModal } from "../components/GeneralModal";
import { Header } from "../components/Header";
import HeaderMenu from "../components/HeaderMenu";
import { IntegrationButton, createShopifyAuthorizaionUrl } from "../components/IntegrationButton";
import { LoginForm } from "../components/LoginForm";
import { auth } from "../firebase";
import { useInitializeQuery, useListIntegrationsByShopQuery } from "../graphql/generated/apollo";
import { useShopContext } from "../shop";
import { generateAndSetState } from "../utils/auth";
import { isFeatureEnabled } from "../utils/featureFlag";

const LinkAreaToLP = () => (
  <Box p={4}>
    <Box pb={2}>アカウントをお持ちでない方</Box>
    <Button as={Link} variant="outline" href="https://reliable-toad.super.site/" isExternal>
      無料で利用申請
    </Button>
  </Box>
);

const firstIntegrationKey = "firstIntegration";

function App() {
  const { user, isLoading: isUserLoading } = useAuthContext();
  const client = useApolloClient();
  const query = new URLSearchParams(window.location.search);
  const firstIntegrationDisclosure = useDisclosure({
    defaultIsOpen: !!query.get("thanks") && !!Cookies.get(firstIntegrationKey),
  });

  const { currentShopId } = useShopContext();
  const initializeHooks = useInitializeQuery({ skip: !user });
  const listIntegrationsHooks = useListIntegrationsByShopQuery({
    variables: {
      shop_id: currentShopId,
    },
    skip: !user || !currentShopId,
  });
  const currentStaff = initializeHooks.data?.shop_staff.find((shopStaff) => shopStaff.shop.id === currentShopId);

  // ShopifyAppのインストールからリダイレクトした際に、ShopifyAppのインストール完了ページにリダイレクトする
  const shopifyStoreId = query.get("shop")?.match(/^(.+)\.myshopify\.com$/)?.[1];
  if (shopifyStoreId && query.get("hmac")) {
    const { state } = generateAndSetState("shopify");

    const callbackUrl = `${import.meta.env.VITE_WEB_DOMAIN.match(/^localhost/) ? "http" : "https"}://${
      import.meta.env.VITE_WEB_DOMAIN
    }/platforms/shopify/auth/callback`;
    const shopifyAuthorizationUrl = createShopifyAuthorizaionUrl(
      shopifyStoreId,
      callbackUrl,
      state,
      query.get("hmac") ?? "",
    );
    window.location.href = shopifyAuthorizationUrl.toString();
  }

  return (
    <div className="App">
      <HeaderMenu />
      {isUserLoading ? (
        <Spinner />
      ) : shopifyStoreId ? (
        <Spinner />
      ) : user ? (
        !currentShopId ? (
          <Spinner />
        ) : (
          <>
            <Header>ショップ</Header>
            <Text>
              {currentStaff?.shop.name}
              {currentStaff?.shop.status !== "ACTIVE" && (
                <Tag colorScheme="red" ml={2}>
                  停止中
                </Tag>
              )}
            </Text>
            <Header>スタッフ</Header>
            <Text>{currentStaff?.name}</Text>
            <Header>連携先プラットフォーム一覧</Header>
            <List spacing={3} textAlign="left">
              {initializeHooks.data?.platforms.map((platform) => {
                const integration = listIntegrationsHooks.data?.integrations.find(
                  (integ) => integ.platform === platform.key,
                );
                const disabled =
                  integration && !!integration.disabled_at && new Date(integration.disabled_at) <= new Date();

                return (
                  <ListItem key={platform.key}>
                    {integration ? (
                      <ListIcon as={disabled ? WarningIcon : CheckCircleIcon} />
                    ) : (
                      <Box display="inline-block" width="16px" height="16px" mr="8px" />
                    )}
                    <Text display="inline-block" mr="8px">
                      {platform.name}
                    </Text>
                    <IntegrationButton
                      id={integration?.id}
                      platform={platform.key}
                      nonce={`${currentShopId}-${user.uid}`}
                      status={
                        integration
                          ? disabled
                            ? "needsReAuth"
                            : platform.key === "YAHOO_SHOPPING" && integration.yahoo_shopping_settings.length === 0
                              ? "needsAdditionalSetting"
                              : "available"
                          : "notConnected"
                      }
                      onStartIntegration={() => {
                        // 初回連携モーダル表示用
                        if (listIntegrationsHooks.data?.integrations.length === 0) {
                          Cookies.set(firstIntegrationKey, "true");
                        }
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Text fontSize="10px" textAlign="left" pt={2}>
              連携可能なストアはラクダスの1ショップにつき各プラットフォーム1つずつです。
              <br />
              最新の連携先にのみ商品データが反映されるため、
              <br />
              各プラットフォームの連携画面に表示されているショップ/ストア名にご注意ください。
            </Text>
            <Header>その他</Header>
            <VStack spacing={3} textAlign="left">
              {/* // 外部の利用ガイドを別ウィンドウで表示させる */}
              <Link
                to={"https://circuly.notion.site/4f634dc33fc14f5b80f67993abca548d"}
                as={ReactRouterLink}
                variant="outline"
                isExternal
              >
                ラクダス ご利用ガイド
              </Link>
              <Link to={"/password-reset"} as={ReactRouterLink} variant="outline">
                パスワードを再設定する
              </Link>
              <Button
                onClick={() => {
                  auth.signOut();
                  client.resetStore();
                }}
              >
                ログアウト
              </Button>
            </VStack>
          </>
        )
      ) : (
        <>
          <LoginForm />
          <Divider p={2} />
          <LinkAreaToLP />
        </>
      )}
      {query.get("thanks") && (
        <GeneralModal
          isOpen={firstIntegrationDisclosure.isOpen}
          onClose={() => {
            Cookies.remove(firstIntegrationKey);
          }}
          title="はじめての連携が完了しました！"
          body={`商品登録時の出品先が追加されました。新しく商品を登録しましょう。
過去に出品された商品はラクダスに取り込まれていません。既存商品を取り込む機能については現在開発中です。今しばらくお待ちください。`}
          mainAction={() => {
            Cookies.remove(firstIntegrationKey);
            firstIntegrationDisclosure.onClose();
          }}
          mainActionLabel="閉じる"
          showCancelButton={false}
        />
      )}
    </div>
  );
}

export default App;
