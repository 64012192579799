import { Button, Flex, Input } from "@chakra-ui/react";
import { useState } from "react";

interface SearchBarProps {
  loading?: boolean;
  onSearch: (searchTerm: string) => void;
}

export const SearchBar = (props: SearchBarProps) => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = () => {
    // トリミングしてから検索を実行
    const trimmedSearchInput = searchInput.trim();
    props.onSearch(trimmedSearchInput);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && event.keyCode !== 229) {
      handleSearch();
    }
  };

  return (
    <Flex>
      <Input
        placeholder="商品のタイトルを検索"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Button onClick={handleSearch} ml={2} isLoading={props.loading}>
        検索
      </Button>
    </Flex>
  );
};
