import {
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Img,
  Input,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import type { FieldErrors, UseFormRegister } from "react-hook-form";
import card5Brand from "../assets/card_5brand.png";
import type { ApplyFormType } from "./ApplyForm";

export default function CreditCardForm({ register, errors }: Props) {
  const divFormControll = {
    mb: "8",
  };

  const labelFormControl = {
    borderRadius: "md",
  };

  const inputFormControl = {
    borderRadius: "md",
    borderColor: "gray.300",
  };

  const mediumInputFormControl = {
    w: "30%",
    textAlign: "left",
    borderRadius: "md",
    borderColor: "gray.300",
  };

  const shortInputFormControl = {
    w: "25%",
    textAlign: "left",
    borderRadius: "md",
    borderColor: "gray.300",
  };

  const errorMessage = {
    color: "#f23a3c",
    textAlign: "left",
    fontSize: "small",
  };

  const validateYear = (value: string) => {
    const year = Number.parseInt(value, 10);
    return (year >= 2000 && year <= 2099) || "有効期限が不正です";
  };

  return (
    <>
      <Heading as="h2" size="large" marginBottom="24px" marginTop="108px">
        お支払い情報
      </Heading>
      <Img src={card5Brand} width="60%" marginBottom="24px" />
      <FormControl sx={divFormControll}>
        <FormLabel sx={labelFormControl}>
          <Text fontSize="sm">
            カード番号{" "}
            <Text as="span" color="#f23a3c">
              *
            </Text>
          </Text>
        </FormLabel>
        <Input
          sx={inputFormControl}
          type="number"
          placeholder="0000000000000000"
          autoComplete="cc-number"
          {...register("cardNumber", {
            required: "この項目は必須です",
          })}
        />
        <Text sx={errorMessage}>{errors.cardNumber?.message}</Text>
      </FormControl>
      <FormControl sx={divFormControll}>
        <FormLabel sx={labelFormControl}>
          <Text fontSize="sm">
            有効期限{" "}
            <Text as="span" color="#f23a3c">
              *
            </Text>
          </Text>
        </FormLabel>
        <HStack>
          <NumberInput sx={shortInputFormControl}>
            <NumberInputField
              autoComplete="cc-exp-month"
              {...register("expirationMonth", {
                required: "この項目は必須です",
              })}
              placeholder="MM"
            />
          </NumberInput>
          <NumberInput sx={mediumInputFormControl}>
            <NumberInputField
              type="number"
              autoComplete="cc-exp-year"
              {...register("expirationYear", {
                required: "この項目は必須です",
                validate: validateYear,
              })}
              placeholder="YYYY"
            />
          </NumberInput>
        </HStack>
        <Text sx={errorMessage}>{errors.expirationYear?.message}</Text>
      </FormControl>
      <FormControl sx={divFormControll}>
        <FormLabel sx={labelFormControl}>
          <Text fontSize="sm">
            カード保有者{" "}
            <Text as="span" color="#f23a3c">
              *
            </Text>
          </Text>
        </FormLabel>
        <Input
          sx={inputFormControl}
          type="text"
          autoComplete="cc-name"
          placeholder="TARO RAKUDASU"
          {...register("billingName", {
            required: "この項目は必須です",
          })}
        />
        <Text sx={errorMessage}>{errors.billingName?.message}</Text>
      </FormControl>
      <FormControl sx={divFormControll}>
        <FormLabel sx={labelFormControl}>
          <Text fontSize="sm">
            セキュリティコード{" "}
            <Text as="span" color="#f23a3c">
              *
            </Text>
          </Text>
        </FormLabel>
        <HStack>
          <NumberInput sx={mediumInputFormControl}>
            <NumberInputField
              autoComplete="cc-csc"
              {...register("securityCode", {
                required: "この項目は必須です",
              })}
            />
          </NumberInput>
        </HStack>
        <Text sx={errorMessage}>{errors.securityCode?.message}</Text>
      </FormControl>
    </>
  );
}

type Props = {
  register: UseFormRegister<ApplyFormType>;
  errors: FieldErrors<ApplyFormType>;
};
