import { Spinner } from "@chakra-ui/react";
import { useAuthContext } from "../auth";
import { Header } from "../components/Header";
import HeaderMenu from "../components/HeaderMenu";
import { PasswordResetForm } from "../components/PasswordResetForm";

export function PasswordReset() {
  const { user, isLoading } = useAuthContext();

  return (
    <div className="App">
      <HeaderMenu />
      <Header>パスワードの再設定</Header>
      {isLoading ? (
        <Spinner />
      ) : user ? (
        <PasswordResetForm email={user.email ?? undefined} state="reset" />
      ) : (
        <PasswordResetForm state="forgot" />
      )}
    </div>
  );
}
