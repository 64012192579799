const authorizationKey = "Bearer " + import.meta.env.VITE_FINCODE_API_KEY;

const fincodeBaseUrl = import.meta.env.VITE_FINCODE_API_URL || "https://api.test.fincode.jp/v1";

const commonHeaders = {
  "Content-Type": "application/json",
  Authorization: authorizationKey,
};

export function createCardToken(requestBody: GetCardTokenRequest): Promise<Response> {
  const requestOptions = {
    method: "POST",
    headers: commonHeaders,
    body: JSON.stringify(requestBody),
  };

  return fetch(`${fincodeBaseUrl}/tokens/cards`, requestOptions);
}

type GetCardTokenRequest = {
  card_no: string;
  expire: string;
  holder_name: string;
  security_code: string;
  number: string;
};
