export function platformKeyToName(platformKey: string) {
  switch (platformKey) {
    case "BASE":
      return "BASE";
    case "STORES":
      return "STORES";
    case "MERCARI_SHOPS":
      return "メルカリShops";
    case "YAHOO_SHOPPING":
      return "Yahoo!ショッピング";
    case "SHOPIFY":
      return "Shopify";
    default:
      return platformKey;
  }
}
