import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, Link, VStack } from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { auth } from "../firebase";

type LoginFormProps = {
  email?: string;
  fixed?: boolean;
};

export function LoginForm(props: LoginFormProps) {
  const [email, setEmail] = useState(props.email ?? "");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);

    // ここでログイン処理を行う
    signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert("入力されたメールアドレスは登録されていません。");
        } else if (error.code === "auth/wrong-password") {
          alert("入力されたメールアドレスまたはパスワードが違います。");
        } else if (error.code === "auth/too-many-requests") {
          alert(
            "一定回数以上の失敗によりロックされました。\n時間をおいてやり直すか、パスワードをリセットしてください。",
          );
        } else {
          alert(`エラーが発生しました。スクリーンショットを保存し、お問い合わせください。\n${error.message}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <VStack spacing={3}>
      <FormControl id="email">
        <FormLabel>メールアドレス</FormLabel>
        <Input
          type="email"
          autoComplete="email"
          value={email}
          onChange={handleEmailChange}
          variant={props.fixed && props.email ? "filled" : "outline"}
          isDisabled={props.fixed}
          isRequired
        />
      </FormControl>
      <FormControl id="password">
        <FormLabel>パスワード</FormLabel>
        <InputGroup>
          <Input
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
            isRequired
          />
          <InputRightElement mr={1}>
            <Button size="sm" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Button
        colorScheme="teal"
        onClick={handleSubmit}
        isLoading={loading}
        isDisabled={email === "" || password === ""}
      >
        ログイン
      </Button>
      <Link to={"/password-reset"} as={ReactRouterLink} variant="outline">
        パスワードを忘れた方はこちら
      </Link>
    </VStack>
  );
}
