import { Button, Link, Spinner, Text, VStack } from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useState } from "react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { useAuthContext } from "../../../auth";
import { useInitializeQuery } from "../../../graphql/generated/apollo";
import { useShopContext } from "../../../shop";
import { generateStateKey } from "../../../utils/auth";

export function Callback() {
  const { user, isLoading: isUserLoading } = useAuthContext();
  if (!user) {
    if (isUserLoading) {
      return <Spinner />;
    }

    return (
      <Button as={ReactRouterLink} to="/">
        ログイン
      </Button>
    );
  }

  const { currentShopId, isLoading: isShopLoading } = useShopContext();
  if (!currentShopId) {
    return <Spinner />;
  }

  const { platform } = useParams();
  const initializeHooks = useInitializeQuery();
  const queryParams = new URLSearchParams(location.search);
  const shopName = initializeHooks.data?.shop_staff.find((s) => s.shop.id === currentShopId)?.shop.name; // TODO: 雑すぎる
  const platformName = initializeHooks.data?.platforms.find(
    (p) => platform === p.key.toLowerCase().replace(/_/g, "-"),
  )?.name;

  const [state, setState] = useState<"initial" | "loading" | "succeeded" | "failed">("initial");

  const connect = (shopId: string, platform: string) => {
    const cookieState = Cookies.get(generateStateKey(platform));
    if (queryParams.get("state") !== cookieState) {
      setState("failed");
      return;
    }

    const url = new URL(
      `/platforms/${platform.toLowerCase().replace(/_/g, "-")}/auth/callback`,
      import.meta.env.VITE_BACKEND_ENDPOINT,
    );
    // queryParamsにshop_idを追加
    queryParams.set("shop_id", shopId);
    url.search = queryParams.toString();

    setState("loading");
    fetch(url.toString(), {})
      .then((resp) => {
        console.debug(resp);
        if (resp.status === 200) {
          alert("連携に成功しました");
          setState("succeeded");
          Cookies.remove(generateStateKey(platform));
          window.location.href = `/?thanks=${platform}`;
        } else {
          setState("failed");
        }
      })
      .catch((e) => {
        console.error(e);
        setState("failed");
      });
  };

  return state === "failed" ? (
    <Text>連携に失敗しました</Text>
  ) : state === "loading" ? (
    <Spinner />
  ) : state === "initial" ? (
    <>
      <VStack>
        <Text>
          ラクダスのショップ「{shopName}」と{platformName}を連携しますか？
        </Text>
        <Button
          onClick={() => {
            connect(currentShopId ?? "", platform ?? "");
          }}
          isLoading={isShopLoading || initializeHooks.loading}
        >
          連携する
        </Button>
        <Link as={ReactRouterLink} to="/">
          キャンセル
        </Link>
      </VStack>
    </>
  ) : (
    <Text>連携に成功しました。</Text>
  );
}
