import { AddIcon, DeleteIcon, DragHandleIcon, UpDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  IconButton,
  Input,
  NumberInput,
  NumberInputField,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DndContext, type DragEndEvent, PointerSensor, useSensor } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useShopContext } from "../shop";
import { OptionalTag } from "./ItemForm/OptionalTag";

type Variant = { id: string; name: string; stockQuantity: string | number; sku: string };

type VariantsFormProps = {
  variants: Variant[];
  onChange: (variants: Variant[]) => void;
};

function VariantForm({
  index,
  variant,
  onChange,
  onDelete,
}: { index: number; variant: Variant; onChange: (variant: Variant) => void; onDelete: () => void }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: variant.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "none",
  };

  return (
    <Flex
      w="100%"
      justify="space-between"
      align="center"
      gap={1}
      key={variant.id}
      ref={setNodeRef}
      style={style}
      cursor="auto"
      {...attributes}
    >
      <Flex justify="center" align="center" gap={1} w={8} {...listeners} cursor="grab">
        <DragHandleIcon />
        <Text>{index + 1}.</Text>
      </Flex>
      <FormControl flex={1}>
        <Input
          borderColor={"gray.300"}
          placeholder="Sサイズ"
          px={2}
          value={variant.name}
          onChange={(e) =>
            onChange({
              ...variant,
              name: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl flex={1}>
        <Input
          borderColor={"gray.300"}
          placeholder="SKU-123"
          px={2}
          value={variant.sku}
          onChange={(e) =>
            onChange({
              ...variant,
              sku: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl w={12}>
        <NumberInput
          borderColor={"gray.300"}
          min={0}
          value={variant.stockQuantity}
          onChange={(value) =>
            onChange({
              ...variant,
              stockQuantity: value,
            })
          }
        >
          <NumberInputField px={2} />
        </NumberInput>
      </FormControl>
      <IconButton aria-label="Remove" icon={<DeleteIcon />} onClick={onDelete} w={8} />
    </Flex>
  );
}

export function VariantsForm({ variants, onChange }: VariantsFormProps) {
  const { currentShopId } = useShopContext();
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const handleVariantChange = (index: number, variant: Variant) => {
    const newVariants = [...variants];
    newVariants[index] = variant;
    onChange(newVariants);
  };

  return (
    <>
      {variants.length === 1 ? (
        <VStack gap={2}>
          <Flex w="100%" justify="space-between" align="center" gap={1}>
            <Text fontSize="sm" flex={1}>
              名前
              <OptionalTag ml={2} />
            </Text>
            <Text fontSize="sm" flex={1}>
              SKU/商品コード
              <OptionalTag ml={2} />
            </Text>
            <Text fontSize="sm" w={12}>
              在庫数
            </Text>
          </Flex>
          <Flex w="100%" justify="space-between" align="center" gap={1}>
            <FormControl flex={1}>
              <Input
                borderColor={"gray.300"}
                placeholder="Sサイズ"
                px={2}
                value={variants[0].name}
                onChange={(e) =>
                  handleVariantChange(0, {
                    ...variants[0],
                    name: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl flex={1}>
              <Input
                borderColor={"gray.300"}
                placeholder="SKU-123"
                value={variants[0].sku}
                onChange={(e) =>
                  handleVariantChange(0, {
                    ...variants[0],
                    sku: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl w={12}>
              <NumberInput
                borderColor={"gray.300"}
                min={0}
                value={variants[0].stockQuantity}
                onChange={(value) =>
                  handleVariantChange(0, {
                    ...variants[0],
                    stockQuantity: value,
                  })
                }
              >
                <NumberInputField px={2} />
              </NumberInput>
            </FormControl>
          </Flex>
        </VStack>
      ) : (
        <DndContext
          sensors={[sensor]}
          onDragEnd={(event: DragEndEvent) => {
            const { active, over } = event;

            if (active && over && active.id !== over.id) {
              const oldIndex = variants.findIndex((v) => v.id === active.id);
              const newIndex = variants.findIndex((v) => v.id === over.id);
              onChange(arrayMove(variants, oldIndex, newIndex));
            }
          }}
        >
          <VStack gap={2}>
            <Flex w="100%" justify="space-between" align="center" gap={1}>
              <Box w={8} />
              <Text fontSize="sm" flex={1}>
                名前
              </Text>
              <Text fontSize="sm" flex={1}>
                SKU/商品コード
                <OptionalTag ml={2} />
              </Text>
              <Text fontSize="sm" w={12}>
                在庫数
              </Text>
              <Box w={10} />
            </Flex>
            <SortableContext items={variants.map((v) => v.id)} strategy={verticalListSortingStrategy}>
              {variants.map((variant, index) => (
                <VariantForm
                  index={index}
                  key={variant.id}
                  variant={variant}
                  onChange={(variant) => handleVariantChange(index, variant)}
                  onDelete={() => {
                    const newVariants = [...variants];
                    newVariants.splice(index, 1);
                    if (newVariants.length === 1) {
                      newVariants[0].name = "";
                    }
                    onChange(newVariants);
                  }}
                />
              ))}
            </SortableContext>
          </VStack>
        </DndContext>
      )}
      <Button
        size="sm"
        mt={2}
        aria-label="Add"
        leftIcon={<AddIcon />}
        onClick={() => {
          const newVariants = [...variants, { id: `${Date.now()}`, name: "", stockQuantity: 1, sku: "" }];
          onChange(newVariants);
        }}
      >
        {`${currentShopId === "d4df73ce-0788-4756-9685-e36acb3954c8" ? "サイズ" : "種類"}を追加する`}
      </Button>
    </>
  );
}
