import { parse } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

const asiaTokyo = "Asia/Tokyo";
const rfc3339Format = "yyyy-MM-dd'T'HH:mm:ssxxx";

// Convert YYYY-MM-DDTHH:mm to RFC3339 style timestamp with JST offset.
export function localDateTimeToJSTTimestamp(localDateTime: string) {
  return formatInTimeZone(new Date(localDateTime), asiaTokyo, rfc3339Format);
}

export function timestampToDateTime(rfc3339String: string) {
  return formatInTimeZone(new Date(rfc3339String), asiaTokyo, "yyyy-MM-dd'T'HH:mm:ss");
}
