import { Button, Link, Spinner, Text, VStack } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useAuthContext } from "../auth";
import { Header } from "../components/Header";
import HeaderMenu from "../components/HeaderMenu";
import { LoginForm } from "../components/LoginForm";
import { PasswordResetForm } from "../components/PasswordResetForm";

export function Setup() {
  const { user, isLoading } = useAuthContext();
  const query = new URLSearchParams(window.location.search);
  const email = query.get("email")
    ? query
        .get("email")
        ?.replaceAll(" ", "+") // エイリアスに用いられる `+` がスペースに変換されるため、元に戻す
    : undefined;

  const BeforeLoggedIn = () => (
    <>
      <Header>1. パスワードを設定する</Header>
      <PasswordResetForm email={email} state="setup" />
      <Text textAlign="left" mt={4} fontSize="sm">
        パスワード設定のメールが送られるため、お好きなパスワードを設定してください。
        <br />
        パスワードは6文字以上で設定し、他のサイト等との使い回しを避けてください。
        <br />
        @rakudasu.firebaseapp.comからのメールが受信できるようにフィルターをご確認ください。
      </Text>
      <Header>2. ログインする</Header>
      <LoginForm email={email} fixed />
    </>
  );

  return (
    <div className="App">
      <HeaderMenu />

      {isLoading ? (
        <Spinner />
      ) : !user ? (
        <BeforeLoggedIn />
      ) : (
        <>
          {/* 連携などのオンボーディングプロセスを記載する */}
          <Header>完了</Header>
          <Text>準備は完了です。利用を開始できます。</Text>
          <VStack spacing={4} mt={4}>
            <Button as={ReactRouterLink} to="/">
              プラットフォームと連携する
            </Button>
            <Button as={ReactRouterLink} to="/sell">
              商品を出品してみる
            </Button>
          </VStack>
        </>
      )}
    </div>
  );
}
